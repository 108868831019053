import React, { useEffect, useState } from 'react';

import { Modal } from 'components/Modal';
import { useParams } from 'react-router-dom';
import { useCreateFile } from '../api/supplierCreateFile';
import { AddButton } from 'components/Button';
import { IconWrapper } from 'components/Icon';
import { Box, Button, Stack, Checkbox, FormControlLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, TextField, Upload } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { fileUploadSchema } from '../validation';
import { useUpdateTenderFile } from '../api/updateTenderFile';

const CustomEndIcon = () => (
  <IconWrapper
    icon="plus"
    sx={{
      height: 24,
      svg: { width: 16, height: 16, color: 'primary.main', stroke: 'white', strokeWidth: 1.33 },
    }}
  />
);

const getDefaultValues = () => ({
  name: '',
  file: null,
});

export const FileUploadModal = ({ handleSuccess, markedForEdit }) => {
  const { name, fileId } = markedForEdit || { name: undefined, fileId: undefined };
  const isUpdating = !!fileId;

  const { id: tenderID, tcId: tcID } = useParams();
  const [open, setOpen] = useState(false);

  const [ skipWatermark, setSkipWatermark] = useState(false)
  const handleSkipWatermarkChange = () => setSkipWatermark(!skipWatermark);

  const methods = useForm({
    defaultValues: isUpdating ? { name } : getDefaultValues(),
    resolver: useYupValidationResolver(fileUploadSchema(isUpdating)),
  });

  const { mutate: createNewFile } = useCreateFile(+tenderID, +tcID);
  const { mutate: updateFile } = useUpdateTenderFile();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    methods.reset({ ...getDefaultValues() });
    setSkipWatermark(false);
    setOpen(false);
  };

  const onSuccess = () => {
    handleSuccess();
    handleClose();
  };

  const onSubmit = async (values) => {
    return isUpdating
      ? updateFile({ tenderID, tcID, fileId, ...values }, { onSuccess })
      : createNewFile({...values, skipWatermark}, { onSuccess });
  };

  const handleDropSingleFile = (acceptedFiles) => {
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (newFile) {
      methods.setValue('file', newFile, { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (markedForEdit) {
      methods.reset({ name });
      setOpen(true);
    }
  }, [markedForEdit]);

  return (
    <>
      <AddButton
        fullWidth
        CustomEndIcon={CustomEndIcon}
        onClick={handleOpen}
        sx={{
          svg: { mt: -1 },
        }}
      >
        Upload file
      </AddButton>
      <Modal title="File upload" open={open} handleClose={handleClose}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack gap={3}>
            <TextField required fullWidth name="name" label="File name" />
            {!isUpdating && (
              <>
                <Upload name="file" onDrop={handleDropSingleFile} />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={skipWatermark}
                      onChange={handleSkipWatermarkChange}
                    />
                  }
                  label="Skip Watermark"
                />
              </>
            )}
            <Stack direction="row" gap={3} justifyContent="center">
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </Modal>
    </>
  );
};
