import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['consortium-group'],
  queryOptions: {},
};

const getConsortiumGroupById = async (dpsID, cgId, companyID) => {
  const { data } = await axios.get(`/api/dps/${dpsID}/consortium_groups/${cgId}`, {headers: {"company_id": companyID}});

  return data;
};

export const useConsortiumGroup = (dpsID, cgId, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, cgId], () => getConsortiumGroupById(dpsID, cgId, user.Company.company_id), {
    initialData: { cg_name: 'Consortium Group', FA: {} },
    ...queryOptions,
  });
};
