import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getCompanyFrameworkAgreementsCount = async (companyID, queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { count },
  } = await axios.get(`/api/companies/${companyID}/framework_agreements/count?${queryString}`);

  return { count };
};

const defaultQueryParams = {
  includeInactive: false,
  expired: false,
  queryOptions: {},
};

export const useCompanyFrameworkAgreementsCount = (companyID, queryParams = {}) => {
  const { includeInactive, expired,  queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    includeinactive: includeInactive,
    onlyexpired: expired,
  };

  return useQuery(
    `company-${companyID}-framework-agreements-count-${expired}`,
    () => getCompanyFrameworkAgreementsCount(companyID, queryStringParams),
    {
      //@ts-ignore
      select: ({ count }) => ({ count }),
      initialData: { count: 0 },
      ...queryOptions,
    }
  );
}