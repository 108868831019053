import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateFile = ({ faId, facId = null, cgId = null, fileId, name }) => {
  let url = `/api/framework_agreements/${faId}/files/${fileId}`;
  if (facId) {
    url = `/api/framework_agreements/${faId}/suppliers/${facId}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/framework_agreements/${faId}/consortium_groups/${cgId}/files/${fileId}`;
  }

  axios.put(url, { name });
}
  

export const useUpdateFrameworkAgreementFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateFile,
    onSuccess: () => {
      enqueueSnackbar('File name was succesfully updated', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
