import React, {useEffect, useState} from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import { useAuth } from 'lib/auth';
import { paths } from 'routes';

import { BreadCrumbs } from 'components/Breadcrumbs';
import { AccordionButton } from 'components/Button';
import { ContentLayout } from 'components/Layout';

import { useUsersById } from '../api/getUserById';
import { isoToDateString } from 'utils/dateFormatter';
import { ProfileImage } from '../components/ProfileImage';
import { EditUser } from '../components/EditUserModal';
import { BannerImage } from 'features/companies/components';
import { Loader } from 'components/Loader';

const UserDetail = ({ label, value, linkTo = '' }) => (
  <Stack gap={1}>
    <Typography variant="caption" color="text.secondary">
      {label}
    </Typography>
    <Typography
      variant="body2"
      color="text.primary"
      component={linkTo ? Link : 'div'}
      {...(linkTo && { to: linkTo })}
    >
      {value || 'Not specified'}
    </Typography>
  </Stack>
);

export const UserDetails = ({}) => {
  const { account } = useAuth();
  let { id } = useParams();
  const [ loading, setLoading ] = useState(false);
  const [timer, setTimer] = useState(null);

  const { data: user, isLoading, isFetched } = useUsersById(id);

  if(loading && !isLoading) {
    setLoading(false);
  }

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }
  

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          <Stack height={1} width={1}>
            <Stack>
              <BreadCrumbs
                items={[
                  {
                    name: 'Users',
                    to: paths.users,
                  },
                  { name: `${user?.first_name} ${user?.last_name}` },
                ]}
              />
              {loading && (
                <Loader style={{marginTop: "40vh"}} />
              )}

              {!loading && (
                <>
              <BannerImage banner={user?.Company?.background_image_url} />

              <Stack direction="row">
                <ProfileImage image={user?.image_url} />

                <Stack flex={1}>
                  <Box
                    px={4}
                    height={70}
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Stack position="relative" flexDirection="row">
                      <Stack>
                        {account.super_admin && (
                          <Divider
                            sx={({ palette }) => ({
                              height: 5,
                              width: 30,
                              bgcolor:
                                user?.status === 'active' ? palette.success.light : palette.error.light,
                              borderRadius: 1,
                            })}
                          />
                        )}
                        <Typography variant="h3">
                          {`${user?.first_name} ${user?.last_name}` || ''}
                        </Typography>
                      </Stack>
                    </Stack>

                    {true && (
                      <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10, top: 20 }}>
                        <Stack gap={0.5}>
                          <EditUser />
                        </Stack>
                      </AccordionButton>
                    )}
                  </Box>
                </Stack>
              </Stack>
              </>
              )}
            </Stack>

            <Stack width={1} direction="row" mt={2} gap={2} height="100%">
              <Stack bgcolor="background.paper" borderRadius={1} flex={3} height={1} />

              <Stack
                boxSizing="border-box"
                bgcolor="background.paper"
                borderRadius={1}
                flex={2}
                height={1}
                py={3}
                px={2}
                gap={3}
              >
                <UserDetail
                  label="Organisation"
                  value={user?.Company?.Name}
                  linkTo={`${user?.Company?.Type === 'supplier' ? paths.suppliers : paths.buyers}${
                    user?.Company?.company_id
                  }`}
                />
                <UserDetail label="Email" value={user?.email} />
                <UserDetail label="Phone" value={user?.phone_number} />
                <UserDetail label="Registration date" value={isoToDateString(user?.created_on, true)} />
                <UserDetail label="Last activity" value={isoToDateString(user?.last_active_on, true)} />
              </Stack>
            </Stack>
          </Stack>
        )
      )}
    </ContentLayout>
  );
};
