import qs from 'qs';

import { axios } from 'lib';

export const getCompanies = async (type, searchTerm = '', includeInactive = true) => {
  const queryStringParams = qs.stringify({
    offset: 0,
    limit: 10,
    search: searchTerm,
    companytype: type,
    includeinactive: includeInactive,
  });

  const { data } = await axios.get(`/api/companies?${queryStringParams}`);

  return data;
};
