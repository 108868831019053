import React from 'react';
import { Stack, Typography } from '@mui/material';

export const InputWrapper = ({
  disabled = false,
  error = false,
  label = '',
  fullWidth = false,
  required = false,
  labelSx = {},
  wrapperSx = {},
  children,
}) => {
  return (
    <Stack gap={0.5} width={fullWidth ? 1 : 250} sx={wrapperSx}>
      <Typography
        variant="caption"
        color={error ? 'error' : /* disabled ? 'text.secondary' : */ 'text.primary'}
        sx={labelSx}
      >{`${label || ''}${required ? ' *' : ''}`}</Typography>
      {children}
    </Stack>
  );
};
