import { axios } from 'lib/axios';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { useAuth } from 'lib/auth';

const createShadowBuyer = async (data) => {
  data.VAT = `SE${data.VAT}01`;
  const { data: shadowBuyer } = await axios.post('/api/companies', {
    ...data,
    Type: 'buyer',
    Country: 'sweden',
    is_shadow: true,
  });

  return shadowBuyer;
};

const createRequest = async (values) => {
  if (values.mainBuyer) {
    const jsonData = {
      main_buyer_id: Number(values.mainBuyer),
      tender_name: values.tenderName,
      description: values.description,
      more_info_url: values.moreInfoURL,
      ref_number: values.refNumber,
      posting_date: values.postingDate,
      last_offer_date: values.lastOfferDate,
      start_date: values.startDate,
      end_date: values.endDate,
      status: values?.status,
      promoted: values.promoted,
    };

    return axios.post('/api/tenders', JSON.stringify(jsonData));
  }

  const { mainBuyerName: Name, VAT, last_updated_user_id } = values;
  const shadowBuyer = await createShadowBuyer({ Name, VAT, last_updated_user_id });

  const data = {
    tender_name: values.tenderName,
    description: values.description,
    more_info_url: values.moreInfoURL,
    ref_number: values.refNumber,
    posting_date: values.postingDate,
    last_offer_date: values.lastOfferDate,
    start_date: values.startDate,
    end_date: values.endDate,
    status: values?.status,
    promoted: values.promoted,
    main_buyer_id: Number(shadowBuyer.company_id),
  };

  return axios.post('/api/tenders', data);
};

export const useCreateTender = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  return useMutation({
    mutationFn: (data) => createRequest({last_updated_user_id: user.user_id, ...data}),
    onSuccess: () => {
      enqueueSnackbar('Tender created successfully', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
