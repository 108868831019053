import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'lib/auth';
import { Box, CircularProgress } from '@mui/material';

export const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const execLogout = async () => {
      await logout();
      navigate("/", { replace: true });
    }

    execLogout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
};
