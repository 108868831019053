import React from 'react';
import { ROOT_PATHS, SUB_PATHS } from './paths';

import { Outlet, Navigate } from 'react-router-dom';

import { MainLayout } from 'components/Layout';

import { RequestAccessForm } from 'features/auth/components/RequestAccessForm';
import { ConsortiumGroupSuppliers, ConsortiumGroupFiles, SuppliersFiles, DetailsView, List as FAList } from 'features/frameworkAgreements';
import { ConsortiumGroupSuppliers as TCGS, ConsortiumGroupFiles as TCGF, SuppliersFiles as TSF, DetailsView as TDV, List as TendersList } from 'features/tenders';
import { DetailsView as DPSDV, List as DPSList } from 'features/dps';
import { List as CompaniesList, DetailsView as CompaniesDetailsView } from 'features/companies';
import { UserDetails, List as UsersList } from 'features/users';
import { List as SubscriptionsList } from 'features/subscriptions';

import { Logout } from './Logout';
import { AccessRequested } from 'features/auth/components/AccessRequested';

const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: `${ROOT_PATHS.USER_SEARCH}`, element: <div>USER SEARCH</div> },
      { path: `${ROOT_PATHS.COMPANY_PROFILE}`, element: <div>COMPANY PROFILE</div> },
      { path: `${ROOT_PATHS.MARKETPLACE}`, element: <FAList /> },
      { path: `${ROOT_PATHS.FRAMEWORK_AGREEMENT}`, element: <FAList /> },
      { path: `${ROOT_PATHS.FRAMEWORK_AGREEMENT_DETAILS}`, element: <DetailsView /> },
      {
        path: `${ROOT_PATHS.FRAMEWORK_AGREEMENT_DETAILS}/${SUB_PATHS.frameworkAgreement.consortiumGroupDetails}`,
        element: <ConsortiumGroupSuppliers />,
      },
      {
        path: `${ROOT_PATHS.FRAMEWORK_AGREEMENT_DETAILS}/${SUB_PATHS.frameworkAgreement.consortiumGroupFiles}`,
        element: <ConsortiumGroupFiles />,
      },
      {
        path: `${ROOT_PATHS.FRAMEWORK_AGREEMENT_DETAILS}/${SUB_PATHS.frameworkAgreement.suppliersFiles}`,
        element: <SuppliersFiles />,
      },
      { path: `${ROOT_PATHS.REQUEST_ACCESS}`, element: <RequestAccessForm /> },
      { path: `${ROOT_PATHS.ACCESS_REQUESTED}`, element: <AccessRequested /> },
      { path: `${ROOT_PATHS.HOMEPAGE}`, element: <Navigate to={`${ROOT_PATHS.FRAMEWORK_AGREEMENT}`} replace={true} /> },
      { path: `${ROOT_PATHS.LOGOUT}`, element: <Logout /> },
      { path: `${ROOT_PATHS.BUYERS}`, element: <CompaniesList companyType={"buyer"}/> },
      { path: `${ROOT_PATHS.BUYERS_DETAILS}`, element: <CompaniesDetailsView key={'buyer'} companyType={"buyer"}/> },
      { path: `${ROOT_PATHS.SUPPLIERS}`, element: <CompaniesList companyType={"supplier"} /> },
      { path: `${ROOT_PATHS.SUPPLIERS_DETAILS}`, element: <CompaniesDetailsView key={'supplier'} companyType={"supplier"} /> },
      { path: `${ROOT_PATHS.USERS}`, element: <UsersList /> },
      { path: `${ROOT_PATHS.USER_DETAILS}`, element: <UserDetails /> },
      { path: `${ROOT_PATHS.SUBSCRIPTIONS}`, element: <SubscriptionsList /> },
      { path: `${ROOT_PATHS.TENDERS}`, element: <TendersList /> },
      { path: `${ROOT_PATHS.TENDERS_DETAILS}`, element: <TDV /> },
      { path: `${ROOT_PATHS.DPS}`, element: <DPSList /> },
      { path: `${ROOT_PATHS.DPS_DETAILS}`, element: <DPSDV /> },
      {
        path: `${ROOT_PATHS.TENDERS_DETAILS}/${SUB_PATHS.tenders.consortiumGroupDetails}`,
        element: <TCGS />,
      },
      {
        path: `${ROOT_PATHS.TENDERS_DETAILS}/${SUB_PATHS.tenders.consortiumGroupFiles}`,
        element: <TCGF />,
      },
      {
        path: `${ROOT_PATHS.TENDERS_DETAILS}/${SUB_PATHS.tenders.suppliersFiles}`,
        element: <TSF />,
      },
    ],
  },
];
