import React from 'react';
import { Box } from '@mui/material';
import { VerticalNavBar } from '..';
import { NotFound } from 'components/NotFound/NotFound';

export const MainLayout = ({ children }) => {
  return (
    <>
      <Box
        flex={1}
        minHeight="100vh"
        width={1}
        maxWidth="100vw"
        display={{ xs: 'flex', md: 'none' }}
        justifyContent="center"
        alignItems="center"
      >
        <NotFound
          wrapperSx={{ width: 1, maxWidth: '100vw', px: 5, textAlign: 'center' }}
          title="Delegon is currently not supported on smaller screens"
          caption="Please open Delegon on a larger device or try in landscape mode"
        />
      </Box>
      {/* <Box minHeight="100vh" minWidth="100vw" display={{ xs: 'none', md: 'flex' }}> */}
      <Box minHeight="100vh" width={1} maxWidth="100vw" display={{ xs: 'none', md: 'flex' }}>
        <VerticalNavBar />
        {children}
      </Box>
    </>
  );

  return (
    <Box minHeight="100vh" width={1} maxWidth="100vw" display="flex">
      <VerticalNavBar />
      {children}
    </Box>
  );
};
