import React from 'react';
import { Avatar as MaterialAvatar } from '@mui/material';

const stringAvatar = (name = '') => {
  const [first, second] = name.split(' ');

  return {
    bgcolor: 'primary.main',
    children: `${first?.[0]?.toUpperCase() || ''}${second?.[0]?.toUpperCase() || ''}`,
  };
};

const getDefaultStyling = (searchResult) => {
  return {
    boxSizing: 'border-box',
    border: 'none !important',
    p: 1,
    width: searchResult ? 25 : 40,
    height: searchResult ? 25 : 40,
    fontSize: searchResult ? '0.875rem' : '1.125rem',
    fontWeight: 500,
    bgcolor: 'grey.100',
    color: 'grey.500',
    alignSelf: 'center',
  }
}

export const Avatar = ({ name, sx = null, searchResult = false }) => {
  sx = getDefaultStyling(searchResult);
  return <MaterialAvatar sx={sx} {...stringAvatar(name)} />;
};
