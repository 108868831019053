import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';

export const YellowMessageBar = ({ message, sx = {}}) => {
  return (
    <MuiTextField
      name="warning"
      required={true}
      value={message}
      sx={{ mb: sx.mb ? sx.mb : 3}}
      fullWidth
      multiline={true}
     // maxRows={3}
      color = "warning"
      disabled
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <IconWrapper icon="info" sx={{height: 24, svg: {stroke: "#FDB526!important", strokeWidth:2}}} />
          </InputAdornment>
        ),
        sx: {
          p: 0,
          backgroundColor: "#FFF6E3!important",
          height: sx.height ? sx.height : 60,
          boxShadow: 25,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#FFF!important",
            disabled: true,
          }
        },
      }}
      inputProps={{
        sx:{
          padding: "0!important",
          pl: "12px!important",
          fontSize:"0.75rem",
          lineHeight: "150%",
          fontWeight: 600,      
        }
      }}
    />
  );
};