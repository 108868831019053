export default function MuiStepIcon(theme) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '2rem',
          height: '2rem',
          marginRight: '1rem',
          //marginTop: '4rem'
        },
        text: {
          fontSize: '1rem',
          fontFamily: theme.typography.fontFamily,
          fontWeight: 700,
          lineHeight: '150%',
          letterSpacing: '-0.24px',
          fontStyle: 'normal',
        }
      },
    },
  };
}