import React, { useEffect } from 'react';

import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';


import { isoToDateString } from 'utils/dateFormatter';

import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useTenderFilesCount } from '../api/getTendersFilesCount';

import { useAuth } from 'lib/auth';
import { paths } from 'routes';

export const TenderListItem = ({
  tender_id,
  tender_name: tenderName,
  ref_number,
  Status,
  created_on,
  edited_on,
  MainBuyer,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: buyersCount },
  } = useTenderCompanyCount(tender_id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount },
  } = useTenderCompanyCount(tender_id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useTenderFilesCount(tender_id);

  return (
    <ListCard>
      <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={tenderName} />
      <Stack flex={{ md: 2, xl: 4 }} justifyContent="center">
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}`}
          color="inherit"
        >
          <Typography variant="body1" fontWeight={700}>
            {tenderName}
          </Typography>
      </Link>
      <Stack direction="row" gap={1}>
        <Typography variant="caption" fontWeight={700}>
          {ref_number}
        </Typography>
        <Divider
          orientation="vertical"
          sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
        />
        <Typography variant="caption" color="text.primary" fontWeight={700}>
          <Link
              component={RouterLink}
              to={`/app/${MainBuyer?.Type}s/${MainBuyer?.company_id.toString()}`}
              color="inherit"
            >
              {MainBuyer?.Name}
          </Link> 
          <Typography variant="caption" fontWeight={600}> ({MainBuyer?.VAT})</Typography>
        </Typography>
      </Stack>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.3}>
        <Typography variant="caption" fontWeight={700}>
          Buyers
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=buyers`}
          color="inherit"
        >
         <Typography variant="body2" fontWeight={700}>
            {buyersCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.35}>
        <Typography variant="caption" fontWeight={700}>
          Suppliers
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=suppliers`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {suppliersCount}
          </Typography>
        </Link>
      </Stack>
      <Stack justifyContent="center" flex={account.super_admin ? 1 : 0.3}>
        <Typography variant="caption" fontWeight={700}>
          Files
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.tendersDetailsView(tender_id)}?tab=files`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {filesCount}
          </Typography>
        </Link>
      </Stack>
      {account.super_admin && (
        <>
          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Created
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(created_on, true)}
            </Typography>
          </Stack>
          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Last Edit
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(edited_on, true)}
            </Typography>
          </Stack>
          <StatusComponent status={Status} />
        </>
      )}
    </ListCard>
  );
};
