import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getFrameworkAgreementsConsortiumGroupFilesCount = (faId, facId, companyID) => {
  return axios.get(
    `/api/framework_agreements/${faId}/consortium_groups/${facId}/files/count`, {headers: {"company_id": companyID}}
  );
};

export const useFrameworkAgreementsConsortiumGroupFilesCount = (faId, facId, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `framework-agreement-consortium-group-${facId}-count`,
    () => getFrameworkAgreementsConsortiumGroupFilesCount(faId, facId, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}
