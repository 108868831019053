import React from 'react';

import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { isoToDateString } from 'utils/dateFormatter';

import { useAuth } from 'lib/auth';

export const UsersListItem = ({
  user_id,
  first_name,
  last_name,
  phone_number,
  Company,
  status,
  last_active_on,
  title,
  email,
  created_on,
  image_url,
}) => {
  const { account } = useAuth();

  return (
    <ListCard>
      <ImageAvatar img={image_url || ''} name={`${first_name} ${last_name}`} />
      <Stack
        flex={{ md: 2, lg: 3 }}
        justifyContent="center"
      >
        <Link
          component={RouterLink}
          to={`/app/users/${user_id.toString()}`}
          color="inherit"
        >
          <Typography variant="body1" fontWeight={700}>
            {first_name} {last_name}
          </Typography>
        </Link>
        <Stack direction="row" gap={1}>
          <Typography variant="caption" fontWeight={600} color={'text.secondary'}>
            {title}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ borderColor: 'grey.500', height: 15, borderWidth: '1px' }}
          />
          <Typography variant="caption" color="text.primary" fontWeight={700}>
            <Link
              component={RouterLink}
              to={`/app/${Company?.Type}s/${Company?.company_id.toString()}`}
              color="inherit"
            >
              {Company?.Name}
            </Link> 
            <Typography variant="caption" fontWeight={600}> ({Company?.VAT})</Typography>
          </Typography>
        </Stack>
      </Stack>

      <Stack justifyContent="center" flex={account.super_admin ? 2 : 1}>
        <Typography variant="caption" fontWeight={700}>
          Phone
        </Typography>

        <Typography variant="body2" fontWeight={700} height={0.4}>
          <Link
            component={RouterLink}
            to={`tel:${phone_number}`}
            // underline="none"
            color="inherit"
          >
            {`${phone_number}`}
          </Link>
        </Typography>
      </Stack>

      <Stack justifyContent="center" flex={account.super_admin ? 3.5 : 1.5}>
        <Typography variant="caption" fontWeight={700}>
          Email
        </Typography>

        <Typography variant="body2" fontWeight={700}>
          <Link
            component={RouterLink}
            to={`mailto:${email}`}
            // underline="none"
            color="inherit"
          >
            {`${email}`}
          </Link>
        </Typography>
      </Stack>
      {account.super_admin && (
        <>
          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Registered
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(created_on, true)}
            </Typography>
          </Stack>

          <Stack justifyContent="center" flex={2}>
            <Typography variant="caption" fontWeight={700}>
              Last Active
            </Typography>

            <Typography variant="body2" fontWeight={700}>
              {isoToDateString(last_active_on, true)}
            </Typography>
          </Stack>
          <StatusComponent status={status} />
        </>
      )}
    </ListCard>
  );
};
