import { axios } from 'lib';

export const downloadFile = async ({dpsID, fileId, dcID = null, cgId = null}) => {  
  let url = `/api/dps/${dpsID}/files/${fileId}`;
  if (dcID) {
    url = `/api/dps/${dpsID}/suppliers/${dcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/dps/${dpsID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  const {
    data: { data },
  } = await axios.get(url);

  try {
    const response = await fetch(decodeURI(data.url));

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const filename = `${data.name}.${data.file_ext}`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    link.click();
  } catch (error) {
    console.log(error);
  }
};

export const donwloadFileFromURL = async (url, name) => {
  try {
    const response = await fetch(decodeURI(url));

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const filename = `${name}`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    link.click();
  } catch (error) {
    console.log(error);
  }
};
