import React from 'react';

import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { IconWrapper } from 'components/Icon';

export const Search = ({ fullWidth = false, sx = {}, handleSearchChange, ...other }) => {
  return (
    <MuiTextField
      placeholder="Search"
      fullWidth={fullWidth}
      sx={{ ...sx }}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconWrapper icon="search" sx={{ svg: { height: 20 } }} />
          </InputAdornment>
        ),
        // endAdornment: (
        //   <InputAdornment position="end">
        //     <Button color="primary" variant="contained" sx={{ width: 82, height: 36 }}>
        //       Search
        //     </Button>
        //   </InputAdornment>
        // ),
        sx: {
          pl: 2,
          pr: 1,
          height: 42,
          borderRadius: 12.5,
          '& .MuiOutlinedInput-input': {
            px: '0px!important',
          },
        },
      }}
    />
  );
};
