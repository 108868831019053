import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['file-preview'],
  queryOptions: {},
};

const previewFile = async (dpsID, fileId, companyID, dcID = null,) => {
  const url = dcID ? `/api/dps/${dpsID}/suppliers/${dcID}/files/${fileId}` : `/api/dps/${dpsID}/files/${fileId}`;
  const {
    data: { data },
  } = await axios.get(url, {headers: {"company_id": companyID}});

  return data;
};

export const useFilePreview = (dpsID, fileId, dcID = null, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, fileId], () => previewFile(dpsID, fileId, user.Company.company_id, dcID), {
    refetchInterval: 5 * 60 * 1000,
    initialData: { url: '', name: '', file_ext: '', mime_type: '' },
    enabled: !!fileId,
    select: ({ url, name, file_ext: FileExt, mime_type: MimeType }) => ({ url, name, FileExt, MimeType }),
    ...queryOptions,
  });
};
