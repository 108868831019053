import {
  Box,
  Collapse,
  Stack,
  Typography,
  ListItemButton,
  Link,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as DelegonLogo } from 'assets/Delegon_Main_Logo.svg';
import DelegonSmallLogo from 'assets/Delegon_Small_Logo.png';
import DelegonSmallLogoGrey from 'assets/Delegon_Small_Logo_Grey.png';


import { IconWrapper } from 'components/Icon';
import { VERTICAL_NAV } from '../config';
import { useNavItems } from '../hooks/useNavItems';
import { useActiveNavLink } from 'routes/hooks/useActiveNavLink';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { useGetCompanyById } from 'features/companies/api/getCompanyById';
import { useNavbar } from 'lib/navbarContext';

import { DELEGON_URL } from 'configuration';

const activeLinkStyling = (palette) => ({
  bgcolor: '#EBFBFF',
  color: palette.primary.main,
  pl: 2,
});
const defaultLinkStyling = (palette) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  color: palette.grey[500],
  p: 2,
});

function NavItem({
  title,
  path,
  Icon,
  hasSubItems,
  active,
  index,
  open,
  selectedIndex,
  onClick,
  closed,
  iconSize,
  isSubItem,
}) {
  return (
    <ListItemButton
      disableGutters
      sx={({ palette }) =>
        selectedIndex === index
          ? { ...defaultLinkStyling(palette), ...activeLinkStyling(palette) }
          : defaultLinkStyling(palette)
      }
      selected={selectedIndex === index}
      onClick={onClick}
      component={RouterLink}
      to={path}
      index={index}
    >
      <ListItemIcon display="flex" sx={{ minWidth: 16 }}>
        {Icon && (
          <Icon
            sx={({ palette }) => ({
              mr: closed ? 0 : 1,
              ml: isSubItem ? 2 : 0,
              svg: {
                height: isSubItem ? iconSize - 2 : iconSize,
                width: isSubItem ? iconSize - 2 : iconSize,
                stroke: palette.grey[500],
                strokeWidth: 1.5,
              },
            })}
          />
        )}
        {!Icon && <Box width={16} />}
      </ListItemIcon>
      <ListItemText
        primary={title}
        sx={{ display: { md: 'none', lg: closed ? 'none' : 'block' }, m: 0 }}
      />

      {hasSubItems && !open && (
        <IconWrapper icon="chevronRight" sx={{ svg: { height: 16, width: 16 } }} />
      )}
      {hasSubItems && open && (
        <IconWrapper icon="chevronUp" sx={{ svg: { height: 16, width: 16 } }} />
      )}
    </ListItemButton>
  );
}

const NavList = ({
  title,
  path,
  Icon,
  subItems,
  index,
  selectedIndex,
  onClick,
  isSubItem = false,
  open,
  setOpen,
  closed,
  iconSize,
}) => {
  const hasSubItems = !!subItems?.length;

  const active = useActiveNavLink(path, true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (active) {
      onClick(null, index, hasSubItems, isSubItem);
    }
  }, [active]);

  const handleListItemClick = (event, index, hasSubItems) => {
    onClick(event, index, hasSubItems, isSubItem);
  };

  return (
    <>
      <NavItem
        title={title}
        path={path}
        Icon={Icon}
        hasSubItems={hasSubItems}
        open={open}
        active={active}
        index={index}
        selectedIndex={selectedIndex}
        onClick={(event) => handleListItemClick(event, index, hasSubItems)}
        closed={closed}
        iconSize={iconSize}
        isSubItem={isSubItem}
      />

      {hasSubItems && (
        <Collapse in={open} unmountOnExit>
          <NavSubList
            items={subItems}
            parentIndex={index}
            selectedIndex={selectedIndex}
            onClick={onClick}
            open={open}
            setOpen={setOpen}
            closed={closed}
            iconSize={iconSize}
          />
        </Collapse>
      )}
    </>
  );
};

const NavSubList = ({
  items,
  parentIndex,
  selectedIndex,
  onClick,
  open,
  setOpen,
  closed,
  iconSize,
}) => {
  return (
    <>
      {items.map((list, index) => (
        <NavList
          key={list.title + list.path}
          {...list}
          index={`${parentIndex}${index}`}
          isSubItem={true}
          open={open}
          setOpen={setOpen}
          selectedIndex={selectedIndex}
          onClick={onClick}
          closed={closed}
          iconSize={iconSize}
        />
      ))}
    </>
  );
};

export const VerticalNavBar = () => {
  const navItems = useNavItems();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [iconSize, setIconSize] = useState(24);

  const { closeMenu, setCloseMenu } = useNavbar();

  const {
    account: { super_admin },
    user,
  } = useAuth();

  const { data: company } = useGetCompanyById(user?.company_id.toString(), {
    enabled: !super_admin,
  });

  const handleListItemClick = (event, index, hasSubItems) => {
    if (hasSubItems) {
      setSelectedIndex(`${index}0`);
      setOpen(!open);
    } else {
      setSelectedIndex(index);
    }
  };

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
    if (!closeMenu) {
      setIconSize(24);
    } else {
      setIconSize(24);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Box
        position="relative"
        height="100vh"
        maxHeight="100vh"
        width={{
          md: VERTICAL_NAV.closedWidth,
          lg: closeMenu ? VERTICAL_NAV.closedWidth : VERTICAL_NAV.width,
        }}
        minWidth={{
          md: VERTICAL_NAV.closedWidth,
          lg: closeMenu ? VERTICAL_NAV.closedWidth : VERTICAL_NAV.width,
        }}
      />
      <Stack
        zIndex={10}
        bgcolor="white"
        position="fixed"
        height={super_admin ? "100vh" : "calc(100% - 52px)"}
        maxHeight={super_admin ? "100vh" : "calc(100% - 52px)"}
        flexShrink={0}
        boxShadow={27}
        borderRight="1px solid"
        borderColor="border.main"
        component="nav"
        width={{
          md: VERTICAL_NAV.closedWidth,
          lg: closeMenu ? VERTICAL_NAV.closedWidth : VERTICAL_NAV.width,
        }}
        top={super_admin ? 0 : 52}
      >
        <Box p={1.5} borderBottom="1px solid" borderColor="border.main">
          <Box
            height={62}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{
              img: { borderRadius: 1 },
              '.small-logo': { display: { md: 'block', lg: closeMenu ? 'block' : 'none' } },
              '.large-logo': { display: { md: 'none', lg: closeMenu ? 'none' : 'block' } },
            }}
          >
            {
              //closeMenu &&
              <img
                className="small-logo"
                src={super_admin ? DelegonSmallLogo : company?.square_logo_url}
                alt={'Delegon'}
                loading="lazy"
                style={{
                  height: 45,
                }}
              />
            }
            {
              //!closeMenu &&
              super_admin ? (
                <DelegonLogo className="large-logo" style={{ height: 62 }} />
              ) : (
                <img
                  className="large-logo"
                  src={company?.lockup_logo_url}
                  alt="company logo"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              )
            }
          </Box>
        </Box>

        <Stack>
          <Box
            display={{ xs: 'none', lg: 'block' }}
            onClick={handleCloseMenu}
            position="absolute"
            right={-10}
            top={0}
            width={10}
            height={1}
            sx={{ cursor: 'pointer', opacity: 1 }}
          />
          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <List sx={{p: 0}}>
              {navItems.map((navItem, index) => (
                <NavList
                  key={navItem.title + navItem.path}
                  open={open}
                  setOpen={setOpen}
                  index={index}
                  selectedIndex={selectedIndex}
                  onClick={handleListItemClick}
                  closed={closeMenu}
                  iconSize={iconSize}
                  {...navItem}
                />
              ))}
            </List>
          </Box>
        </Stack>
        <Stack flex={1} justifyContent="flex-end">
          <Box
            component={RouterLink}
            to={DELEGON_URL}
            borderTop="1px solid"
            borderColor="border.main"
            color="text.secondary"
            sx={{ textDecoration: 'none' }}
            target="_blank"
            rel="noopener"
          >
            <ListItemButton
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 1,
                pl: 3,
                pr: 2,
              }}
            >
              <Typography
                variant="body1"
                fontWeight={700}
                sx={{ display: { md: 'none', lg: closeMenu ? 'none' : 'block' } }}
              >
                Delegon.com
              </Typography>
              <img
                className="small-logo"
                src={DelegonSmallLogoGrey}
                alt={'Delegon'}
                loading="lazy"
                style={{
                  height: 30,
                  color: "#FFF",
                }}
              />
            </ListItemButton>
          </Box>
          <Box
            component={RouterLink}
            to={paths.logout}
            borderTop="1px solid"
            borderColor="border.main"
            color="text.secondary"
            sx={{ textDecoration: 'none' }}
          >
            <ListItemButton
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                pl: 3,
              }}
            >
              <Typography
                variant="body1"
                fontWeight={700}
                sx={{ display: { md: 'none', lg: closeMenu ? 'none' : 'block' } }}
              >
                Log out
              </Typography>
              <IconWrapper icon="logOut" />
            </ListItemButton>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
