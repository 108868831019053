import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { alpha } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { InputWrapper } from './InputWrapper';

export function DateInput({
  name,
  label,
  labelSx = {},
  helperText = '',
  datePickerProps = {},
  fullWidth = false,
  disabled = false,
  required,
  wrapperSx = {},
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <InputWrapper
          error={!!fieldState?.error}
          label={label}
          fullWidth={fullWidth}
          required={required}
          labelSx={labelSx}
          wrapperSx={wrapperSx}
        >
          <DatePicker
            {...field}
            {...datePickerProps}
            {...rest}
            disabled={disabled}
            views={['year', 'month', 'day']}
            PaperProps={{
              sx: {
                border: (theme) => `solid 1px ${alpha(theme.palette.background.neutral, 0.5)}`,
                boxShadow: (theme) => `${theme.customShadows.searchBar}`,
              },
            }}
            value={field.value}
            slotProps={{
              textField: {
                error: !!fieldState.error,
                helperText: fieldState.error?.message || '',
              },
            }}
          />
        </InputWrapper>
      )}
    />
  );
}
