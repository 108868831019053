import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getRelatedFrameworkAgreements = async (companyID, queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: frameworkAgreements, total },
  } = await axios.get(`/api/companies/${companyID}/framework_agreements?${queryString}`);

  return { frameworkAgreements, total };
};

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: false,
  includeExpired: false,
  sortBy: 'dateCreated',
  queryKey: ['companies-related-framework-agreements'],
  queryOptions: {},
};

export const useGetRelatedFrameworkAgreements = (companyID, queryParams = {}) => {
  const { page, limit, search, includeInactive, includeExpired, sortBy, queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    includexpired: includeExpired,
    sortby: sortBy,
    offset: page * limit,
  };

  return useQuery(
    [...queryKey, companyID],
    () => getRelatedFrameworkAgreements(companyID, queryStringParams),
    {
      select: ({ frameworkAgreements, total }) => ({ frameworkAgreements, total }),
      initialData: { frameworkAgreements: [], total: 0 },
      ...queryOptions,
    }
  );
}