import React from 'react';
import { Box, Stack, Typography } from '@mui/material';


export const Status = ({ status }) => {
  return (
    <Stack justifyContent="center" flex={0.5}>
      <Typography variant="caption" fontWeight={700}>
        Status
      </Typography>
      <Stack direction="row" alignItems="center" gap={1} height={0.5}>
        <Box
          borderRadius="100%"
          width={8}
          height={8}
          border="1px solid"
          borderColor={status === 'active' ? 'success.light' : 'error.light'}
          bgcolor={status === 'active' ? 'success.light' : 'error.light'}
        />
      </Stack>
    </Stack>
  );
};
