import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getRelatedCompaniesCount = (companyID, companytype) => {
  const queryString = qs.stringify({ companytype });
  return axios.get(
    `/api/companies/${companyID}/companies/count?${queryString}`
  );
};

export const useRelatedCompaniesCount = (companyID, type, options = {}) =>
  useQuery(
    `company-${companyID}-related-companies-count-${type}`,
    () => getRelatedCompaniesCount(companyID, type),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );