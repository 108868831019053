import { useSnackbar } from 'notistack';

import { axios } from 'lib';
import { useMutation } from 'react-query';

const removeRemoveFile = async ({ dpsID, dcID = null, cgId = null, fileId }) => {
  let url = `/api/dps/${dpsID}/files/${fileId}`;
  if (dcID) {
    url = `/api/dps/${dpsID}/suppliers/${dcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/dps/${dpsID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  axios.delete(url);
}
  

export const useRemoveFile = (onFileRemoval) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: removeRemoveFile,
    onSuccess: () => {
      enqueueSnackbar('The file was succesfully deleted', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      onFileRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
