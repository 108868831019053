import { axios } from "lib/axios"
import { auth } from "lib/firebase"
import { sendEmailVerification, createUserWithEmailAndPassword } from 'firebase/auth';
import { paths } from 'routes';

export const acceptInvitationNewAccount = async (data, user) => {
  try {
    await createUserWithEmailAndPassword(auth, data.companyEmail, data.password);
    return await sendRequestNewAccount(data, user);
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("Account already exists.");
    } else if (error.code === 'auth/weak-password') {
      throw new Error("Password is too weak.");
    } else if (error.code === 'auth/invalid-email') {
      throw new Error("Invalid e-mail address");
    } else {
      throw error;
    }
  }
}

export const acceptInvitationExistingAccount = async (data, user) => {
  try {
    return await sendRequestExistingAccount(data, user);
  } catch (error) {
    throw error;
  }
}

const sendRequestNewAccount = async (data, user) => {
  const jsonData = {
    "account": {
      "account_id": user?.account_id,
      "status": "active",
    },
    "user": {
      "user_id": user?.user_id,
      "first_name": data.firstName,
      "last_name": data.lastName,
      "phone_number": data.phoneNumber,
      "email": data.companyEmail,
      "role": "company_admin",
      "status": "active",
    },
    "company": {
      "company_id": user?.company_id,
      "is_shadow": false,
    }
  }

  return await axios.post("/auth/accept-invitation", JSON.stringify(jsonData));
}

const sendRequestExistingAccount = async (data, user) => {
  const jsonData = {
    "account": {
    },
    "user": {
      "user_id": user?.user_id,
      "first_name": data.firstName,
      "last_name": data.lastName,
      "phone_number": data.phoneNumber,
      "email": data.companyEmail,
      "role": "company_admin",
      "status": "active",
    },
    "company": {
      "company_id": user?.company_id,
      "is_shadow": false,
    }
  }

  return await axios.post("/auth/accept-invitation", JSON.stringify(jsonData));
}

