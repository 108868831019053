import React, { useState } from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { PersonalDetailsForm } from '../components/PersonalDetailsFormBuyers';
import { EmailSentForm } from '../components/EmailSentForm';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Box } from '@mui/material';
import { Loader } from 'components/Loader';

export const BuyersRegister = () => {
  const [state, setState] = useState({
    step: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyEmail: '',
  });

  const [ error, setError ] = useState(false);
  const steps = ['', ''];  

  // proceed to the next step
  const nextStep = () => {   
    const { step } = state;
    setState({ ...state, step: step + 1 });
  };

  // Handle fields change
  const handleChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };

  const { step } = state;

  return (
    <AuthLayout title="Register">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height={1}
        px={{ xs: 5, sm: 1 }}
        pb={{ xs: 2, sm: 0 }}
      >
        <Stepper
          nonLinear
          activeStep={step}
          sx={{ mt:5 }}
        >
          {steps.map((label, index) => (
            <Step key={index} sx={{ pl: 0, mb: 5 }}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {step === 0 && (
          <PersonalDetailsForm nextStep={nextStep} handleChange={handleChange} values={state} />
        )}
        {step === 1 && (
          // <FinishRegistrationForm handleChange={handleChange} values={state} setState={setState} />
          <EmailSentForm values={state} error={error} setError={setError} />
        )}
      </Box>
    </AuthLayout>
  );
};
