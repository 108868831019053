import React from 'react';
import { Stack, SpeedDial, SpeedDialAction, Typography } from '@mui/material';

import { ListCard } from 'components/Card';
import { isoToDateString } from 'utils/dateFormatter';
import { IconWrapper } from 'components/Icon';

const getFileIcon = (mimeType = '') => {
  if (mimeType === 'application/pdf') return 'pdf';
  else if (mimeType.includes('image/')) return 'photo';
  return 'downloadFile';
};

export const FileListItem = ({
  name,
  mime_type: MimeType = '',
  created_on,
  handleDownload,
  handleFilePreview,
  markForDelete,
  markForEdit,
}) => {
  const imageIcon = getFileIcon(MimeType);
  return (
    <>
      <ListCard>
        <Stack direction="row" alignItems="center" flex={12} gap={1.5}>
          <Stack
            justifyContent="center"
            alignItems="center"
            width={40}
            height={40}
            p={1}
            boxSizing="border-box"
            borderRadius="100%"
            bgcolor="grey.300"
          >
            <IconWrapper
              icon={imageIcon}
              sx={{
                svg: {
                  width: 20,
                  height: 20,
                  color: 'white',
                  path: imageIcon === 'pdf' && {
                    fill: 'white',
                    strokeWidth: '1',
                    stroke: 'white',
                  },
                  ...(imageIcon === 'photo' && { stroke: 'white' }),
                },
              }}
            />
          </Stack>

          <Typography
            variant="body1"
            fontWeight={700}
            fontSize="1rem"
            sx={{ textDecoration: 'underline', cursor:"pointer" }}
            onClick={(MimeType.includes('application/pdf') || MimeType.includes('image/')) ? handleFilePreview : handleDownload}
          >
            {name}
          </Typography>
        </Stack>

        <Stack justifyContent="center" flex={1} position="relative" minWidth={200}>
          <Typography variant="caption" fontWeight={700}>
            Created
          </Typography>

          <Typography variant="body2" fontWeight={700}>
            {isoToDateString(created_on, true)}
          </Typography>

          <SpeedDial
            onClick={(e) => e.stopPropagation()}
            direction="left"
            ariaLabel="File actions"
            icon={
              <IconWrapper
                icon="elipsisVertical"
                sx={({ palette }) => ({
                  svg: { stroke: palette.grey[500], color: palette.grey[500] },
                })}
              />
            }
            sx={({ palette }) => ({
              '& > button': {
                boxShadow: 'none',
                bgcolor: palette.background.paper,
                width: 36,
                height: 36,
              },
              '& > button:hover': { bgcolor: palette.background.paper },
              position: 'absolute',
              right: -14,
              top: 'calc(50% - 28px)',
              zIndex: 1,
            })}
          >
            <SpeedDialAction
              tooltipTitle="Edit"
              onClick={markForEdit}
              sx={({ palette }) => ({
                bgcolor: palette.grey[50],
                '&:hover': { bgcolor: palette.grey[200] },
              })}
              icon={
                <IconWrapper
                  icon="edit"
                  sx={({ palette }) => ({
                    svg: {
                      width: 20,
                      height: 20,
                      stroke: palette.grey[500],
                      color: palette.grey[500],
                    },
                  })}
                />
              }
            />

            <SpeedDialAction
              tooltipTitle="Delete"
              onClick={markForDelete}
              sx={({ palette }) => ({
                bgcolor: palette.grey[50],
                '&:hover': { bgcolor: palette.grey[200] },
              })}
              icon={
                <IconWrapper
                  icon="trash"
                  sx={({ palette }) => ({
                    svg: {
                      width: 20,
                      height: 20,
                      stroke: palette.grey[500],
                      color: palette.grey[500],
                    },
                  })}
                />
              }
            />

            {(MimeType.includes('application/pdf') || MimeType.includes('image/')) && (
              <SpeedDialAction
                tooltipTitle="Preview"
                onClick={handleFilePreview}
                sx={({ palette }) => ({
                  bgcolor: palette.grey[50],
                  '&:hover': { bgcolor: palette.grey[200] },
                })}
                icon={
                  <IconWrapper
                    icon="search"
                    sx={({ palette }) => ({
                      svg: {
                        width: 20,
                        height: 20,
                        stroke: palette.grey[500],
                        color: palette.grey[500],
                      },
                    })}
                  />
                }
              />
            )}
          </SpeedDial>
        </Stack>
      </ListCard>
    </>
  );
};
