import React, { useRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import Collapse from '@mui/material/Collapse';

import { Snackbar } from './Snackbar';

export default function SnackbarProvider({ children }) {
  const notistackRef = useRef(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      TransitionComponent={Collapse}
      variant="info" // Set default variant
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      Components={{
        default: Snackbar,
        info: Snackbar,
        success: Snackbar,
        warning: Snackbar,
        error: Snackbar,
      }}
    >
      {children}
    </NotistackProvider>
  );
}
