import React, { useState } from 'react';

import { AccordionListItem } from 'components/Button';
import { ImageUpload } from 'components/ImageUpload';
import { useParams } from 'react-router-dom';
import { useImageUpload } from '../api/uploadImage';
import { useGetCompanyById } from '../api/getCompanyById';
import { SubscribeForm } from '../../subscriptions/components/SubscribeForm';
import { ModifyForm } from '../../subscriptions/components/ModifyForm';
import { SuperAdminSubscribeForm } from '../../subscriptions/components/SuperAdminSubscribeForm';

export const Subscription = ({currentLevel = 'free', isSuperAdmin = false, company}) => { 
  const { id: companyId } = useParams();
  const [open, setOpen] = useState(false);

  const { refetch } = useGetCompanyById(companyId);  

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Subscription</AccordionListItem>

      {(!currentLevel || currentLevel === "free") && (
        <SubscribeForm
          open={open}
          handleClose={() => setOpen(false)}
          handleSuccess={refetch}
          company={company}
        />
      )}

      {currentLevel && currentLevel !== "free" && (
        <>
        {isSuperAdmin && (
          <SuperAdminSubscribeForm
            open={open}
            handleClose={() => setOpen(false)}
            handleSuccess={refetch}
            company={company}
            currentLevel={currentLevel}
          />
        )}
        {!isSuperAdmin && (
          <ModifyForm
            open={open}
            handleClose={() => setOpen(false)}
            handleSuccess={refetch}
            company={company}
            currentLevel={currentLevel}
          />
        )}
        </>
      )}
    </>
  );
};
