import React, { createContext, useContext, useState } from 'react';

const NavbarContext = createContext({ closeMenu: false, setCloseMenu: (open) => open });

const NavbarProvider = ({ children }) => {
  const [closeMenu, setCloseMenu] = useState(false);

  return (
    <NavbarContext.Provider value={{ closeMenu, setCloseMenu }}>{children}</NavbarContext.Provider>
  );
};

const useNavbar = () => {
  const context = useContext(NavbarContext);

  return context;
};

export { NavbarProvider, useNavbar };
