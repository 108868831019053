import React from 'react';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';
import { Stack, Typography, Link } from '@mui/material';
import { paths } from 'routes';
// import { Link } from 'react-router-dom';


export const BlueMessageBar = ({ message, href, sx = {}}) => {
  return (
    <Link href={href} underline="none" color="inherit">
      <Stack gap={1} width={1} height={64}
        sx={({ palette }) => ({
          justifyContent: 'flex-start',
          alignItems: 'center',
          bgcolor: "#ECF3FF",
          fontSize: '0.75rem',
          fontWeight: 600,
          mt:2.5,
          mb:2.5,
          cursor: "pointer",
          boxShadow: 25,
          borderRadius: 1,
          borderColor: "#FFF!important",
          flexDirection: "row",
          ...sx,
        })}
      >
        <IconWrapper icon="info" sx={{height: 24, pl: 2, svg: {stroke: "#3B82F6!important", strokeWidth:2}}} />
        <Typography variant="content"
          sx={{
            pl: 1,
            pr: 1,
            fontSize: '0.875rem',
            fontWeight: '500',
          }}
        >
          {message}
        </Typography>
      </Stack>
    </Link>
  );
};