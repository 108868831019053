import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';

import { IconWrapper } from 'components/Icon';

export const AccordionListItem = ({ onClick = () => {}, children }) => (
  <Button
    fullWidth
    variant="text"
    onClick={onClick}
    sx={({ palette }) => ({
      justifyContent: 'flex-start',
      borderRadius: 1.25,
      bgcolor: palette.grey[50],
      fontSize: '0.75rem',
      fontWeight: 600,
      px: 1.5,
      py: 0.5,
    })}
  >
    {children}
  </Button>
);

export const AccordionButton = ({ label, sx = {}, children }) => {
  return (
    <Box position="relative" height={1} display="flex">
      <Box position="absolute" right={0} top={0}>
        <Accordion
          sx={{
            borderRadius: 1.5,
            p: 0.5,
            zIndex: 10,
            ...sx,
          }}
        >
          <AccordionSummary
            expandIcon={
              <IconWrapper
                icon="chevronDown"
                sx={({ palette }) => ({
                  svg: {
                    color: palette.primary.main,
                    stroke: palette.primary.main,
                    width: 20,
                    height: 20,
                    mt: 0.25,
                  },
                })}
              />
            }
            sx={{
              p: 0,
              minHeight: '0 !important',
              '& > *': { my: '0 !important', minHeight: '0 !important' },
            }}
          >
            <Typography variant="body1" fontWeight={700} color="primary.main" sx={{ pl: 1 }}>
              {label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              mt: 1.5,
              padding: 0,
            }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};
