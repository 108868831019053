import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { AccordionButton } from 'components/Button';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';

import { useQueryParams } from 'hook/useQueryParams';

import { paths } from 'routes';

import { useTender } from '../api/getTenderById';
import { DetailViewTabList } from '../components/TabList';
import { DetailsTab } from '../components/DetailsTab';
import { BuyersTab } from '../components/BuyersTab';
import { SuppliersTab } from '../components/SuppliersTab';
import { EditForm } from '../components/EditForm';
import { useTenderCompanyCount } from '../api/getTendersCompanyCount';
import { useTenderFilesCount } from '../api/getTendersFilesCount';
import { StatusToggleButton } from '../components/StatusToggleButton';
import { useAuth } from 'lib/auth';
import { FilesTab } from '../components/FilesTab';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';
import { Loader } from 'components/Loader';

export const DetailsView = () => {
  const queryParams = useQueryParams();
  const [ loading, setLoading ] = useState(false);
  const { account, user } = useAuth();
  let { id } = useParams();
  const [tab, setTab] = useState(queryParams?.tab || 'details');
  const [timer, setTimer] = useState(null);

  const { data: tender, refetch: refetchTender, error, isError,  isLoading: tenderLoading, isFetched: isFetched1 } =
    useTender(id);
  const {
    //@ts-ignore
    data: { count: buyersCount, isLoading: buyersCountLoading, isFetched: isFetched2 },
  } = useTenderCompanyCount(id, 'buyer');
  const {
    //@ts-ignore
    data: { count: suppliersCount, isLoading: suppliersCountLoading, isFetched: isFetched3 },
  } = useTenderCompanyCount(id, 'supplier');
  const {
    //@ts-ignore
    data: { count: filesCount, isLoading: filesCountLoading, isFetched: isFetched4 },
  } = useTenderFilesCount(id);

  const switchTab = (_, newTab) => setTab(newTab);

  const isLoading = tenderLoading || buyersCountLoading || suppliersCountLoading || filesCountLoading;
  const isFetched = isFetched1 || isFetched2 || isFetched3 || isFetched4;

  useEffect(() => {  
    if (isLoading) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isFetched) {    
    if (loading) {      
      setTimeout(() => setLoading(false), 1000);
    }
  }

  if (!loading && isError && error.message === "Object doesn't exist") {
    return <div>{error?.message}</div>;
  }

  return (
    <ContentLayout>
      {isLoading && !loading ? (
        <></>
      )
      : (
        loading ? (
          <Loader style={{marginTop: "40vh"}}/>
        )
        : (
          !loading && isError ? (
            <PremiumBanner message={`This Tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />
          ) : (
            <Stack height="100%">
              <BreadCrumbs
                items={[
                  { name: 'Tenders', to: paths.tenders },
                  { name: tender?.tender_name ?? 'Details View' },
                ]}
              />
              <Box
                height={70}
                sx={{
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Stack>
                  {account.super_admin && (
                    <Divider
                      sx={({ palette }) => ({
                        height: 5,
                        width: 30,
                        bgcolor:
                          tender?.Status === 'active'
                            ? palette.success.light
                            : palette.error.light,
                        borderRadius: 1,
                      })}
                    />
                  )}
                  <Typography variant="h3">{tender?.tender_name || ''}</Typography>
                </Stack>

                {account.super_admin && (
                  <AccordionButton label="Actions" sx={{ width: 130, zIndex: 10 }}>
                    <Stack gap={0.5}>
                      <EditForm tender={tender} refetch={refetchTender}/>

                      <StatusToggleButton
                        refetch={refetchTender}
                        status={tender?.Status}
                      />
                    </Stack>
                  </AccordionButton>
                )}
              </Box>

              <DetailViewTabList
                value={tab}
                onChange={switchTab}
                tabs={[
                  { label: 'Details' },
                  { label: 'Buyers', count: buyersCount },
                  { label: 'Suppliers', count: suppliersCount },
                  { label: 'Files', count: filesCount },
                ]}
              />

              <Stack
                px={4}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
                height="100%"
              >
                {error && (
                  <PremiumBanner message={`This Tender is only available for Premium members. Click here for more information`} handleSuccess={refetchTender} currentLevel={user.Company.subscription_level} />
                  // <div>{error?.message}</div>
                )}
                {!error && (
                  <>
                {/* {tab === "details" && ( */}
                  <DetailsTab currentTab={tab} tender={tender} />
                {/* )} */}

                {/* {tab === "buyers" && ( */}
                  <BuyersTab
                    currentTab={tab}
                    mainBuyer={{ Company: tender?.MainBuyer || null }}
                  />
                {/* )} */}

                {/* {tab === "suppliers" && ( */}
                  <SuppliersTab currentTab={tab} />
                {/* )} */}

                {/* {tab === "files" && ( */}
                  <FilesTab currentTab={tab} />
                {/* )} */}
                </>
              )}
              </Stack>
            </Stack>
          )
        )
      )}
    </ContentLayout>
  );
};
