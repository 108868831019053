import { createTheme } from '@mui/material';

import { palette } from './palette';
import { typography } from './typography';
import { shadows } from './shadows';
import overrideStyles from './overrides';

const theme = createTheme({
  palette,
  typography,
  shadows,
  shape: { borderRadius: 8 },
});
theme.components = overrideStyles(theme);

export default theme;
