import React from 'react';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';

import { InputWrapper } from './InputWrapper';
import { AddButton } from 'components/Button';

//  THE PROPERTY optionLabelName IS REQUIRED AS THE AUTOCOMPLETE COMPONENT
//  REQUIRES IT'S ITEM OBJECT TO HAVE THE LABEL PROPERTY AS AN

//  !!! UNIQUE !!!

//  IDENTIFIER. IT (optionLabelName) MAPPS SOME EXISTING PROPERTY OF THE ITEM OBJECT
//  TO THE LABEL

export const CardAutocomplete = ({
  name = '',
  label = '',
  fullWidth = false,
  wrapperSx = {},
  onChange,
  ItemCard,
  handleNotFoundButtonClick = () => null,
  queryFunction,
  optionLabelName, //
  companyType = "Supplier"
}) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useQuery([name, inputValue], () => queryFunction(inputValue), {
    enabled: !!inputValue,
    initialData: { data: [] },
    select: ({ data }) => {
      return data.map((item) => ({
        ...item,
        label:
          typeof item[optionLabelName] === 'number'
            ? item[optionLabelName].toString()
            : item[optionLabelName],
      }));
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const debouncedSetInputValue = useDebouncedCallback(
    (newInputValue) => setInputValue(() => newInputValue),
    300
  );
  useEffect(() => {
    if (inputValue) refetch();
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <InputWrapper fullWidth={fullWidth} wrapperSx={wrapperSx} label={label}>
      <Autocomplete
        fullWidth={true}
        // name={name}
        value={null}
        blurOnSelect={true}
        options={data}
        getOptionLabel={(item) => item.label}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          event.preventDefault();
          onChange(newValue);
        }}
        open={!loading && inputValue.length > 0}
        onInputChange={(_, newInputValue) => {
          setLoading(() => !!newInputValue);
          debouncedSetInputValue(newInputValue);
        }}
        noOptionsText={
          inputValue ? (
            <AddButton fullWidth onClick={handleNotFoundButtonClick}>
              Create Shadow {companyType}
            </AddButton>
          ) : (
            'Please enter a search parameter'
          )
        }
        renderOption={(props, option) => <ItemCard {...{ ...props, ...option }} />}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="primary" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              '*': { fontWeight: 'bold !important' },
              '.MuiInputBase-root': { backgroundColor: 'white', py:0 },
            }}
            placeholder="Please enter a search parameter"
          />
        )}
        ListboxProps={{
          sx: {
            p: 0,
            boxShadow: 25,
            borderWidth: 2,
            maxHeight: 300,
            '& > *': {
              p: '16px !important',
              bgcolor: 'white !important',
            },
            '& > *:hover': { bgcolor: 'white !important' },
            '& > :not(:last-child)': {
              borderBottom: '2px solid',
              borderColor: 'border.main',
            },
          },
        }}
      />
    </InputWrapper>
  );
};
