import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { PaperCard } from 'components/Card';
import { EXTERNAL, paths } from 'routes';
import { FormProvider, TextField } from 'components/Form';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { personalDetailsSchemaBuyers } from '../validation';
import { BlueMessageBar } from 'components/BlueMessageBar';

export const PersonalDetailsForm = ({
  nextStep,
  handleChange,
  values,
  acceptInvitation = false,
}) => {
  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(personalDetailsSchemaBuyers),
    values: values,
  });

  const onSubmit = async (data) => {
    nextStep();
  };

  const message = `You are registering as a Buyer. Click here if you want to register as a Supplier`;

  return (
    <PaperCard width={{ xs: 1, sm: 368 }} maxWidth={{ sm: 368 }} sx={{mb:5}}>
      <Stack>
        <Typography variant="h5" sx={{ mb: 0 }}>
          Personal details
          <BlueMessageBar message={message} href={paths.auth.register} />
        </Typography>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <TextField
              name="firstName"
              label="First Name"
              placeholder="Please enter your first name"
              required={true}
              type="text"
              value={values.firstName}
              onChange={handleChange('firstName')}
              sx={{ mb: 2 }}
              fullWidth
              disabled={acceptInvitation}
            />
            <TextField
              name="lastName"
              label="Last Name"
              placeholder="Please enter your last name"
              required={true}
              type="text"
              value={values.lastName}
              onChange={handleChange('lastName')}
              sx={{ mb: 2 }}
              fullWidth
              disabled={acceptInvitation}
            />
            <TextField
              name="phoneNumber"
              label="Phone Number"
              placeholder="Please enter your phone number"
              required={true}
              type="text"
              value={values.phoneNumber}
              onChange={handleChange('phoneNumber')}
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              name="companyEmail"
              label="Your work e-mail"
              placeholder="Please enter your work e-mail"
              required={true}
              type="email"
              value={values.companyEmail}
              onChange={handleChange('companyEmail')}
              sx={{ mb: 2 }}
              fullWidth
              disabled={acceptInvitation}
            />

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              {acceptInvitation ? 'Save' : 'Next'}
            </Button>
            <Stack
              align="center"
              gap={1.5}
              sx={{
                a: {
                  color: 'inherit',
                  fontWeight: 700,
                  textTransform: 'none',
                },
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: 400, color: "text.primary"}}>
                By continuing, you agree to Delegon’s{' '}
                <a href={EXTERNAL.TERMS_OF_SERVICE}  target='_blank' rel="noreferrer">Terms of Service</a>{' and '}
                <a href={EXTERNAL.PRIVACY_POLICE}  target='_blank' rel="noreferrer">Privacy Policy</a>
              </Typography>

              {!acceptInvitation && (
                <>
                  <Divider sx={{ width: 1 }} />

                  <Typography variant="caption" sx={{ fontWeight: 400, color: "text.primary" }}>
                    Already a member? <Link to={paths.auth.login}>Log in</Link>
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </PaperCard>
  );
};
