import React, { useEffect, useState } from 'react';
import { AuthLayout } from '../components/AuthLayout';
import { PersonalDetailsForm } from '../components/PersonalDetailsForm';
import { LoginForm } from '../components/LoginForm';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { useQueryParams } from 'hook/useQueryParams';
import { getUserByID } from '../api/getUserByID';
import { PaperCard } from 'components/Card';
import { Button, Stack, Typography } from '@mui/material';
import { RedMessageBar } from 'components/RedMessageBar';

import { acceptInvitationNewAccount, acceptInvitationExistingAccount } from '../api/acceptInvitation';
import { useAuth } from 'lib/auth';

export const AcceptInvitation = () => {
  const { account, user } = useAuth();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const userID = queryParams.user_id;
  const code = queryParams.code;
  const newAccount = queryParams.new
  const [error, setError ] = useState(null);
  const [userResp, setUserResp] = useState(null);

  const email = atob(code.toString());

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyEmail: email,
    password: '',
    confirmPassword: '',
    // companyName: '',
    // country: 'Sweden',
    // vat: '',
  });

  const handleChange = input => e => {
    setValues({ ...values, [input]: e.target.value });
  }

  const onSubmitNewAccount = async () => {
      try {
      await acceptInvitationNewAccount(values, userResp)
    } catch (error) {
      console.log(error);
    }

    navigate(paths.homepage);
  };

  const onSubmitExistingAccount = async () => {
    try {
    await acceptInvitationExistingAccount(values, userResp)
  } catch (error) {
    console.log(error);
  }

  navigate(paths.homepage);
};

  useEffect(() => {
    const initialAction = async () => {
      try {
        const user = await getUserByID(userID);
        if (user.email !== email) {
          setError("Action not allowed!");
        } else {
          setUserResp(user);
          setValues({ ...values, firstName: user.first_name, lastName: user.last_name, companyEmail: user.email });
        }        
      } catch (error) {
        setError(error.message);
      }
    }

    initialAction();
  }, []);  

  return (
    <AuthLayout title="Accept Invitation">
    {error && (
      <PaperCard width={368} maxWidth={368}>
        <Stack>
          <Typography variant="h5" sx={{ mb: 3 }}>
            Accept invitation
          </Typography>
          <RedMessageBar message={error} />
          <Button size="large" variant="contained" color="primary" sx={{ mt: 2, mb: 0.5 }} href={paths.homepage}>
            Return to home
          </Button>
        </Stack>
      </PaperCard>
    )}
    {(!error && newAccount.toString() === "true") && (
      <PersonalDetailsForm 
        nextStep={ onSubmitNewAccount }
        handleChange={ handleChange }
        values={ values }
        acceptInvitation = {true}
      />
    )}
    {(!error && newAccount.toString() === "false") && (
      <LoginForm 
        values={ values }
        registrationFlow={ true }
        existingUser={ true }
        onSuccess={ onSubmitExistingAccount }
        msg={"We have detected that the e-mail is already in use. Please log in to accept invitation."}
        acceptInvitation={ true }
      />
    )}
    </AuthLayout>
  );
};
