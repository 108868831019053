import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const removeConsortiumGroupSupplier = ({ faId, cgId, cgcId }) =>
  axios.delete(`/api/framework_agreements/${faId}/consortium_groups/${cgId}/companies/${cgcId}`);

export const useRemoveConsortiumGroupSupplier = (onSupplierRemoval) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: removeConsortiumGroupSupplier,
    onSuccess: () => {
      enqueueSnackbar('Supplier successfully removed from the consortium group', {
        variant: 'success',
      });

      onSupplierRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
