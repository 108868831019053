import React from 'react';
import { ReactComponent as NotFoundIcon } from 'assets/NotFound.svg';
import { Stack, Typography } from '@mui/material';

export const NotFound = ({ title = '', caption = '', wrapperSx = {} }) => {
  return (
    <Stack gap={1} width={1} alignItems="center" border={0} sx={wrapperSx}>
      <NotFoundIcon />
      <Typography variant="h5" fontWeight={700} textAlign="center">
        {title}
      </Typography>
      <Typography variant="body1" color="text.secondary" textAlign="center">
        {caption}
      </Typography>
    </Stack>
  );
};
