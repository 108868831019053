import { axiosAuth } from 'lib/axios';
import { useQuery } from 'react-query';

const getByIDRequest = async (id) => {
  return await axiosAuth.get(`/auth/users/${id}`);
};

// export const useGetUserByID = (id, config = {}) => {
//   return useQuery(['user', id], () => getByIDRequest(id), {
//     select: ({ data }) => data,
//     ...config,
//   });
// };

export const getUserByID = async (id) => {
  try {
    const user = await getByIDRequest(id);
    return user.data;
  } catch (error) {
    throw error;
  }
}