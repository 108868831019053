import React, { useState } from 'react';

import { AccordionListItem } from 'components/Button';
import { ImageUpload } from 'components/ImageUpload';
import { useParams } from 'react-router-dom';
import { useImageUpload } from '../api/uploadImage';
import { useGetCompanyById } from '../api/getCompanyById';

export const LogoUpload = ({}) => {
  const { id: companyId } = useParams();
  const [open, setOpen] = useState(false);

  const { refetch } = useGetCompanyById(companyId);

  const { mutate: uploadImage } = useImageUpload(companyId, 'big_logo', refetch); //  ADD BODY PARAM NAME

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Change logo</AccordionListItem>

      <ImageUpload
        aspect={116 / 31}
        uploadImage={uploadImage}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};
