//@ts-nocheck

import 'react-image-crop/dist/ReactCrop.css';

import React, { forwardRef } from 'react';
import { Box, Button, Stack, styled } from '@mui/material';
import ReactCrop from 'react-image-crop';

import { IconWrapper } from 'components/Icon';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ImageUploadStepOne = forwardRef(
  (
    {
      setCrop,
      setCompleteCrop,
      step,
      setStep,
      image,
      imgSrc,
      aspect,
      crop,
      onImageLoad,
      onSelectFile,
      completeCrop,
    },
    imgRef
  ) => (
    <Stack gap={3} display={step === 0 ? 'flex' : 'none'}>
      <Box
        height={600}
        // overflow="auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={({ palette }) => ({
          borderTop: `1px solid ${palette.grey[200]}`,
          borderBottom: `1px solid ${palette.grey[200]}`,

          '::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },

          '& .image-to-crop': {
            // width: 1,
            maxHeight: 600,
            height: 1,
            width: 1,
            objectFit: 'contain !important',
          },
        })}
      >
        {image && imgSrc === image && (
          <Box>
            <img src={image} alt="profile" />
          </Box>
        )}
        {imgSrc && imgSrc !== image && (
          <ReactCrop
            aspect={aspect}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(crop) => setCompleteCrop(crop)}
          >
            <img
              className="image-to-crop"
              src={imgSrc}
              ref={imgRef}
              onLoad={onImageLoad}
              alt="crop me"
            />
          </ReactCrop>
        )}
        {!imgSrc && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex={1}
            width={1}
            height={1}
            sx={({ palette }) => ({ bgcolor: palette.grey[100] })}
          >
            <IconWrapper icon="photo" sx={{ svg: { height: 96, width: 96 } }} />
          </Box>
        )}
      </Box>
      <Stack direction="row" px={4} pb={4} justifyContent="space-between">
        <Button component="label" variant="outlined" color="primary">
          Select Image
          <VisuallyHiddenInput type="file" accept="image/*" in onChange={onSelectFile} />
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setStep(1)}
          disabled={
            image === imgSrc ||
            !completeCrop ||
            (completeCrop && (completeCrop.width === 0 || completeCrop.height === 0))
          }
        >
          Preview Image
        </Button>
      </Stack>
    </Stack>
  )
);
