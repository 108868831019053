import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getCompaniesCount = (dpsID, companytype, companyID) => {
  const queryString = qs.stringify({ companytype });
  return axios.get(
    `/api/dps/${dpsID}/companies/count?${queryString}`,
    {headers: {"company_id": companyID}}
  );
};

export const useCompaniesCount = (dpsID, type, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `dps-${dpsID}-${type}-count`,
    () => getCompaniesCount(dpsID, type, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}