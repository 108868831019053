import { createTheme } from '@mui/material';

export const shadows = [
  ...createTheme().shadows,
  '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)', // base shadow on figma   index starts at 25
  '0px 4px 6px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.06)', // md shadow on figma
  '0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px 0px rgba(0, 0, 0, 0.1)', // lg shadow on figma
  '0px 10px 10px 0px rgba(0, 0, 0, 0.04), 0px 20px 25px 0px rgba(0, 0, 0, 0.1)', // xl shadow on figma
  '0px 25px 50px 0px rgba(0, 0, 0, 0.25)', // 2xl shadow on figma
  '0px 2px 4px 0px rgba(0, 0, 0, 0.06) inset', // inner shadow on figma
];
