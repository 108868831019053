import { axios } from 'lib';
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

const updateCompanyStatus = ({ companyID, status }) => {
  try {
    return axios.put(
      `/api/companies/${companyID}/status`,
      JSON.stringify({ Status: status })
    );
  } catch (error) {
    return error;
  }
};

export const useUpdateCompanyStatus = (refetch) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateCompanyStatus,
    onSuccess: () => {
      enqueueSnackbar('Company status successfully updated', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      refetch();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
