import React, { useState } from 'react';
import { TextField } from './TextField';
import InputAdornment from '@mui/material/InputAdornment';


export const VATNumber = ({
  name,
  label = '',
  sx={},
  required = false,
  onChange = null,
  value = null,
  registration = false,
  ...other
}) => {
  const [vat, setVat ] = useState(value);
  const handleChange = (e) => {
    let val = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    if (val.length > 10) {
      val = val.slice(0,10);
    }

    setVat(val);
    if (onChange) {
      e.target.value = val;
      onChange(e);
    }
  };

  const handlePaste = (e) => {
    let val = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    if (val.length > 10) {
      val = val.slice(0,10);
    }

    setVat(val);
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={({ palette }) => ({
              mr:"3px",
              '& .MuiTypography-root': {
                color: palette.grey[300],
              },
              })
            }
          >
            SE
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={({ palette }) => ({
              ml:"3px",
              '& .MuiTypography-root': {
                color: palette.grey[300],
              },
              })
            }
          >
            01
          </InputAdornment>
        ),
      }}
      required
      fullWidth
      name={name}
      label={label}
      placeholder="xxxxxxxxxxx"
      value={vat}
      sx={({ palette }) => ({
        '& .MuiInputBase-input': {
          pl: "0px!important",
          pr: "0px!important",
          width: registration ? "25%" : "21%",
        },
        '& .MuiInputBase-input::placeholder': {
          // color: palette.grey[700]
        },
        ...sx
      })}
      onChange={handleChange}
      onPaste={handlePaste}
      {...other}
    />
  );
};
