import React from 'react';

import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { FormHelperText } from '@mui/material';

// import RejectionFiles from './errors-rejection-files';
// import SingleFilePreview from './preview-single-file';

// ----------------------------------------------------------------------

const UploadInput = ({
  disabled = false,
  error = null,
  helperText = null,
  //
  file,
  onDelete = () => {},
  //
  files = [],
  //   thumbnail,
  onUpload = () => {},
  onRemove = () => {},
  onRemoveAll = () => {},
  sx = {},
  ...other
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    // fileRejections
  } = useDropzone({
    disabled,
    ...other,
  });

  const hasFile = !!file;

  const hasError = isDragReject || !!error;

  const renderPlaceholder = (
    <Stack spacing={3} alignItems="center" justifyContent="center" flexWrap="wrap">
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="h6">Drop or Select file</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click
          <Box
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
              textDecoration: 'underline',
            }}
          >
            browse
          </Box>
          thorough your machine
        </Typography>
      </Stack>

      {hasFile && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Selected file:
          <Box
            component="span"
            sx={{
              mx: 0.5,
              color: 'primary.main',
            }}
          >
            {file.name}
          </Box>
        </Typography>
      )}
    </Stack>
  );

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          p: 5,
          outline: 'none',
          borderRadius: 1,
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
          // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          border: (theme) => `1px solid ${alpha(theme.palette.grey[500], 0.2)}`,
          transition: (theme) => theme.transitions.create(['opacity', 'padding']),
          '&:hover': {
            opacity: 0.72,
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
        }}
      >
        <input {...getInputProps()} />

        {renderPlaceholder}
      </Box>

      <Box ml={-2}>{helperText && helperText}</Box>
    </Box>
  );
};

export const Upload = ({ name, helperText = '', ...other }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <UploadInput
            file={field.value}
            error={!!error}
            helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
            {...other}
          />
        );
      }}
    />
  );
};
