import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate  } from 'react-router-dom';
import { FormProvider, TextField } from 'components/Form';
import { Button, Box, Stack, Typography } from '@mui/material';
import { YellowMessageBar } from 'components/YellowMessageBar';
import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { requestAccessSchema } from '../validation';
import { useAuth } from 'lib/auth';
import { useSnackbar } from 'notistack';
import { PremiumBanner } from '../../../features/subscriptions/components/PremiumBanner';

export const RequestAccessForm = ({ values, createFirebaseUser = false }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [comment, setComment] = useState('');
  const { account, user, requestaccess, createfirebaseuser} = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    mode: 'onSubmit',
    resolver: useYupValidationResolver(requestAccessSchema),
    values: { comment: comment },
  });

  const companyName = values?.companyName ? values.companyName : state?.values?.companyName;
  const vat = values?.vat ? values.vat : state?.values?.vat;
  values = state?.values ? state?.values : values;  

  const onSubmit = async (data) => {
    try {
      if (createFirebaseUser) {
        const data = {
          companyEmail: values.companyEmail,
          password: values.password,
        }

        const firebaseUser = await createfirebaseuser(data);
        values.firebaseUID = firebaseUser.user.uid;
      }

      await requestaccess(values, data.comment);
      enqueueSnackbar('Access requested successfuly', { variant: 'success' });
      const confirmationRequired = createFirebaseUser;
      navigate(confirmationRequired ? paths.auth.accessRequested : paths.accessRequested, { state: confirmationRequired });
    } catch (error) {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    }    
  };

  const warningMsg = `${companyName} (${vat}) already exists. Would you like to request access? We will send an e-mail to administrator with your message.`;

  return (
  <>
    { !createFirebaseUser && (
      <>
      {!account.super_admin && (
        <Box>
          <PremiumBanner sx={{mt:0, position:"fixed", width:"100vw", zIndex:100, left: 0}} message={`Read more about our Premium offerings here`} currentLevel={user.Company.subscription_level} showLock={false} />
        </Box>
      )}
      <Stack
        minHeight="100vh"
        width="100%"
        minWidth={435}
        bgcolor="#d9d9d9"
        justifyContent="center"
        position="absolute"
        zIndex={3}
      >
        <Box display="flex" justifyContent="center" alignItems="center" height={1}>
          <PaperCard width={369} maxWidth={369} height={351} maxHeight={351} bgcolor="#fff" sx={{mb:5}}>
            <Stack>
              <Typography variant="h5" sx={{ mb: 0.5 }}>
                Request access
              </Typography>
              <YellowMessageBar message={warningMsg} sx={{ height: 70 }} />
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack>
                  <TextField
                    name="comment"
                    label="Comment (min. 20 characters)"
                    placeholder="Please enter your comment"
                    required={true}
                    type="text"
                    value={comment}
                    onChange={(e) => { setComment(e.target.value); }}
                    sx={{
                      mb: 2,
                      '& .MuiInputBase-root, .MuiInputBase-input': {
                        p: 0,
                      },
                    }}
                    fullWidth
                    multiline
                    rows={2}
                  />

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mb: 0.5 }}
                  >
                    Send request
                  </Button>
                </Stack>
              </FormProvider>
            </Stack>
          </PaperCard>
        </Box>
      </Stack>
      </>
      )}
      {createFirebaseUser && (
        <PaperCard width={369} maxWidth={369} height={351} maxHeight={351} bgcolor="#fff" sx={{mb:5}}>
          <Stack>
            <Typography variant="h5" sx={{ mb: 0.5 }}>
              Request access
            </Typography>
            <YellowMessageBar message={warningMsg} sx={{ height: 70 }} />
            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack>
                <TextField
                  name="comment"
                  label="Comment (min. 20 characters)"
                  placeholder="Please enter your comment"
                  required={true}
                  type="text"
                  value={comment}
                  onChange={(e) => { setComment(e.target.value); }}
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  fullWidth
                  multiline
                  rows={2}
                />

                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2, mb: 0.5 }}
                >
                  Send request
                </Button>
              </Stack>
            </FormProvider>
          </Stack>
        </PaperCard>
      )}
      </>
  );
};
