import React from 'react';

import { Stack, Typography, Box, IconButton } from '@mui/material';
import MuiModal from '@mui/material/Modal';
import { IconWrapper } from 'components/Icon';

const defaultStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  width: 432,
  borderRadius: 2,
  p: 4,
  border: '1px solid',
  borderColor: 'grey.200',
  boxShadow: 27,
};

export const Modal = ({ title, open = false, handleClose, sx = {}, children, gap = 3 }) => {
  const onClose = (_, reason) => {
    if (reason && reason === "backdropClick" ) {
      return;
    }

    handleClose();
  }

  return (
    <MuiModal open={open} onClose={onClose} disableEnforceFocus disableRestoreFocus={true}>
      <Box sx={{ ...defaultStyling, ...sx }}>
        <Stack gap={gap}>
          <Stack
            className="header-wrapper"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="bold">
              {title}
            </Typography>

            <IconButton onClick={handleClose}>
              <IconWrapper icon="xMark" sx={{ svg: { mt: -1 } }} />
            </IconButton>
          </Stack>
          {children}
        </Stack>
      </Box>
    </MuiModal>
  );
};
