import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import 'react-image-crop/dist/ReactCrop.css';
import { IconWrapper } from 'components/Icon';
import { ImageUpload } from 'components/ImageUpload';
import { useParams } from 'react-router-dom';
import { useImageUpload } from '../api/uploadImage';
import { useGetCompanyById } from '../api/getCompanyById';

const baseStyles = {
  width: 100,
  innerHeight: 1,
  borderRadius: 1,
  // overflow: 'hidden',
};
const imageWrapperStyles = {
  bottom: 0,
  height: 100,
  position: 'absolute',
  cursor: 'pointer',

  ...baseStyles,
};

const placeholderStyles = {
  ...baseStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const SquareImage = ({ sx = {}, image }) => {
  const { id: companyId } = useParams();
  const [open, setOpen] = useState(false);

  const { refetch } = useGetCompanyById(companyId);

  const { mutate: uploadImage } = useImageUpload(companyId, 'square_logo', refetch);

  return (
    <>
      <ImageUpload
        uploadImage={uploadImage}
        image={image}
        open={open}
        handleClose={() => setOpen(false)}
      />

      <Box
        ml={4}
        width={100}
        onClick={() => setOpen(true)}
        sx={{
          position: 'relative',
          innerHeight: 1,
          borderRadius: 1,
          cursor: 'pointer',
        }}
      >
        <Stack
          position="absolute"
          width={1}
          bottom={24}
          height={100}
          justifyContent="center"
          alignItems="center"
          borderRadius={1}
          overflow="hidden"
          sx={{ border: '1px solid', borderColor: 'grey.200', bgcolor: 'grey.300' }}
        >
          {!image && (
            <IconWrapper
              icon="photo"
              sx={{ svg: { width: 40, height: 40, mt: -1, stroke: 'white', color: 'white' } }}
            />
          )}

          {image && (
            <Box width={100} height={100} sx={{ img: { width: 1, height: 1 } }}>
              <img src={image} alt="company" />
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};
