import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { alpha } from '@mui/material';
import { DateField as MuiDateField } from '@mui/x-date-pickers/DateField';
import { InputWrapper } from './InputWrapper';

export function DateField({
  name,
  label,
  labelSx = {},
  helperText = '',
  datePickerProps = {},
  fullWidth = false,
  disabled = false,
  required = false,
  wrapperSx = {},
  ...rest
}) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <InputWrapper
          error={!!fieldState?.error}
          label={label}
          fullWidth={fullWidth}
          required={required}
          labelSx={labelSx}
          wrapperSx={wrapperSx}
        >
          <MuiDateField
            {...field}
            {...datePickerProps}
            {...rest}
            disabled={disabled}
            views={['year', 'month', 'day']}
            // PaperProps={{
            //   sx: {
            //     border: (theme) => `solid 1px ${alpha(theme.palette.background.neutral, 0.5)}`,
            //     boxShadow: (theme) => `${theme.customShadows.searchBar}`,
            //   },
            // }}
            defaultValue={field.value}
            slotProps={{
              textField: {
                error: !!fieldState.error,
                helperText: fieldState.error?.message || '',
              },
            }}
          />
        </InputWrapper>
      )}
    />
  );
}
