import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "lib/firebase"
import { onAuthStateChanged, signOut, verifyPasswordResetCode, confirmPasswordReset, applyActionCode } from "firebase/auth";
import { loginWithEmailAndPassword, getAccountData, getAccountDataOnly, verifyExistance, register as registerAPI, signUp, activateProfile, forgotPassword, requestAccess, createFirebaseUser } from "features/auth"

const AuthContext = createContext();

const AuthProvider = ({ children }) => {  
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [user, setUser] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null)

  useEffect(() => {
    const onAuthStateChange = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser && !account) {
        try {
          let acc;      
          if (JSON.parse(localStorage.getItem("delegonHasUsers"))) {
            acc = await getAccountData();
          } else {
            acc = await getAccountDataOnly();
          }
          setError(null);
          setFirebaseUser(currentUser);
          setAccount(acc.data);
          setUser(acc.data?.users[0]);
        } catch (error) {
          logout();
          setError(error?.response?.data?.error);
        }
      }

      setLoading(false);
    });
  
    return () => {
     onAuthStateChange();
    }
  }, [])

  const login = async (data, hasUsers) => {
    try {
      const acc = await loginWithEmailAndPassword(data, hasUsers);
      setError(null);
      setAccount(acc);
      setUser(acc?.users[0]);
      await localStorage.setItem("delegonHasUsers", hasUsers);
      await localStorage.setItem("delegonUserID", acc?.users[0]?.user_id);
      await localStorage.setItem("delegonCompanyID", acc?.users[0]?.Company.company_id);
    } catch (error) {
      await logout();
      throw error;
    }
  }

  const logout = async () => {
    try {
      await signOut(auth);
      setFirebaseUser(null);
      setAccount(null);
      setUser(null);
      emptyLocalStorage()
    } catch (error) {
      emptyLocalStorage()
      throw error;
    }
  }

  const checkExistance = async (email, vat) => {
    const result = await verifyExistance(email, vat);
    return result?.data;
  }

  const register = async (data, newFirebaseUser = true) => {
    try {
      await registerAPI(data, newFirebaseUser);
    } catch (error) {
      await logout();
      throw error;
    }
  }

  const createfirebaseuser = async (data) => {
    try {
      return await createFirebaseUser(data);
    } catch (error) {
      await logout();
      throw error;
    }
  }

  const activate = async (firebaseUID, accountID, userID, companyID) => {
    try {
      await activateProfile(firebaseUID, accountID, userID, companyID);
    } catch (error) {
      await logout();
      throw error;
    }
  }

  const forgotpassword = async (email) => {
    try {
      await forgotPassword(email);
    } catch (error) {
      throw error;
    }
  }

  const resetPassword = async (actionCode, password) => {
    try {
      await confirmPasswordReset(auth, actionCode, password);
    } catch (error) {
      throw error;
    }
  }

  const verifyResetCode = async (actionCode) => {
    try {
      return await verifyPasswordResetCode(auth, actionCode);
    } catch (error) {
      if (error.code === 'auth/expired-action-code') {
        throw new Error("Code expired. Please try again.");
      } else if (error.code === 'auth/invalid-action-code') {
        throw new Error("Invalid code. Please try again.")
      } else {
        throw error;
      }
    }
  }

  const verifyActionCode = async (actionCode) => {
    try {
      return await applyActionCode(auth, actionCode);
    } catch (error) {
      if (error.code === 'auth/expired-action-code') {
        throw new Error("Code expired. Please try again.");
      } else if (error.code === 'auth/invalid-action-code') {
        throw new Error("Invalid code. Please try again.")
      } else {
        throw error;
      }
    }
  }

  const requestaccess = async(data, message) => {
    try {
      return await requestAccess(data, message)      
    } catch (error) {
      throw error
    }
  }

  const signup = async(data) => {
    try {
      return await signUp(data);
    } catch (error) {
      throw error
    }
  }

  return (
    <AuthContext.Provider value={{ firebaseUser, account, user, login, logout, checkExistance, register, signup, activate, forgotpassword, resetPassword, verifyResetCode, verifyActionCode, requestaccess, createfirebaseuser, error }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('context error')
  }

  return context
}

const emptyLocalStorage = () => {
  localStorage.removeItem("delegonHasUsers");
  localStorage.removeItem("delegonUserID");
  localStorage.removeItem("delegonCompanyID");
}

export { AuthProvider, useAuth };