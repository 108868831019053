import { axios } from "lib/axios"
import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

export const useInviteUser = (cID = 1) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    //@ts-ignore
    mutationFn: (data) => sendInvitation(cID, data),
    onSuccess: () => enqueueSnackbar('User succesfully invited', { variant: 'success' }),
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
}

const sendInvitation = async (cID, data) => {
  const jsonData = {
    "account": {
      "email": data.companyEmail,
      "status": "invited",
    },
    "user": {
      "first_name": data.firstName,
      "last_name": data.lastName,
      "email": data.companyEmail,
      "status": "invited",
      "company_id": cID,
      "last_updated_user_id": data.accountID,
      "role": "company_admin",
      "title": "Administrator",
    },
    "company": {
      "company_id": cID,
      "last_updated_user_id": data.accountID,
    }
  }

  return await axios.post(`/api/companies/${cID}/users`, JSON.stringify(jsonData));
}

