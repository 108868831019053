import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const createConsortiumGroup = async ({
  tenderID,
  cg_name,
  companies_ids,
  Last_updated_user_id = 1,
}) => {
  const {
    data: { cg_id },
  } = await axios.post(
    `/api/tenders/${tenderID}/consortium_groups`,
    JSON.stringify({ tender_id: +tenderID, cg_name, Last_updated_user_id })
  );

  const { data } = await axios.post(
    `/api/tenders/${tenderID}/consortium_groups/${cg_id}/companies`,
    JSON.stringify({
      tender_id: +tenderID,
      cg_id: +cg_id,
      companies_ids,
      company_type: 'supplier',
    })
  );

  return data;
};

export const useCreateConsortiumGroup = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: createConsortiumGroup,
    onSuccess: () => {
      enqueueSnackbar('Supplier consortium group successfully added to a tender', {
        variant: 'success',
      });
    },
    onError: (error) => {
      //@ts-ignore
      const { response } = error;

      if (response?.data?.description) {
        enqueueSnackbar(
          response.data.description.toLowerCase().includes('duplicate key')
            ? 'Consortium group name already exists'
            : response.data.description.includes,
          { variant: 'error' }
        );
        //@ts-ignore
      } else enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
