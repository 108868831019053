import React, { useState, useEffect} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { EXTERNAL } from 'routes';
import { FormProvider } from 'components/Form';
import { Modal } from 'components/Modal';

import { useCompanyUsersCount } from '../../companies/api/getCompanyUsersCount';

import { useAuth } from 'lib/auth';

const getDefaultValues = (usersCount) => ({
  subscription_level: '',
  number_of_users: usersCount,
  promo_code: '',
});

export const ModifyForm = ({handleClose, handleSuccess, open, currentLevel, company = null }) => {
  const { user } = useAuth();
  const companyID = company?.company_id ? company.company_id : user.Company.company_id;
  const companyType = company?.Type ? company.Type : user.Company.Type;
  const {
    //@ts-ignore
    data: { count: usersCount },
  } = useCompanyUsersCount(companyID);

  const [ numberOfUsers, setNumberOfUsers ] = useState('');
  
  const methods = useForm();

  const onSubmit = async () => {
  };

  const onClose = () => {
    methods.reset({ ...getDefaultValues(usersCount) });
    setNumberOfUsers(usersCount)
    handleClose();
  };

  useEffect(() => {
    setNumberOfUsers(usersCount)
  }, [usersCount])
  
  return (
    <Modal title="Current subscription level" handleClose={onClose} open={open} gap={0}>
      <Stack>
        <Stack>
          <Typography variant="h6" sx={{fontWeight: 500, textTransform: 'capitalize' }}>
            {currentLevel}
          </Typography>
          <Typography variant="body2" sx={{ mb: 3, fontWeight: 400 }}>
             Read more about our subscription levels <a href={companyType === 'buyer'? EXTERNAL.PRICING_BUYERS : EXTERNAL.PRICING_SUPPLIERS}>here</a>
          </Typography>
        </Stack>

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack>
            <Stack>
              <Typography variant="h5">
                Number of users
              </Typography>
              <Typography variant="h5" sx={{ mb: 3, fontWeight: 400 }}>
                {numberOfUsers}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h5" sx={{ mb: 3 }}>
                Change or cancel your subscription?
              </Typography>
              <Typography variant="body2" sx={{ mb: 3, fontWeight: 400 }}>
              Would you like to change the number of users, the subscription level or cancel your subscription? Please send us an email via the link below and we will assist!
              </Typography>
            </Stack>

            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, mb: 0.5 }}
            >
              Send e-mail
            </Button>
          </Stack>
        </FormProvider>
      </Stack>    
    </Modal>
  );
};
