import React from 'react';

import { Box, Button, Typography, Link, Stack } from '@mui/material';
import { IconWrapper } from 'components/Icon';
import { useAuth } from 'lib/auth';
import { Link as RouterLink } from 'react-router-dom';

import { LAYOUT, VERTICAL_NAV } from '../config';

import { Search } from 'components/Form';

import { getCompanies } from '../../../features/frameworkAgreements/api/getCompanies';
import { useNavbar } from 'lib/navbarContext';
import { ImageAvatar } from 'components/Avatar';

const ItemCard = ({
  Name: name,
  VAT: vatNumber,
  Description: description,
  Type: companyType,
  company_id,
  square_logo_url,
  ...rest
}) => {
  return (
    <Stack gap={1} alignItems={'flex-start !important'} {...rest}>
      <Link
        component={RouterLink}
        to={`/app/${companyType}s/${company_id.toString()}`}
        underline="none"
        color="inherit"
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ img: { borderRadius: 1, width: 20, height: 20 } }}
        >
          <ImageAvatar img={square_logo_url || ''} name={name} searchResult />
          <Typography variant="body1" fontWeight="bold">
            {name} ({vatNumber})
          </Typography>
        </Stack>
      </Link>
    </Stack>
  );
};

export const ContentHeader = ({
  headerSize = 'small',
  superAdmin = false,
}) => {
  const { account } = useAuth();
  const { closeMenu } = useNavbar();

  return (
    <Box
      zIndex={10}
      bgcolor="white"
      minWidth={LAYOUT.width}
      width={{
        xs: `calc(100vw - ${VERTICAL_NAV.closedWidth})`,
        lg: `calc(100vw - ${closeMenu ? VERTICAL_NAV.closedWidth : VERTICAL_NAV.width})`,
      }}
      left={{
        xs: VERTICAL_NAV.closedWidth,
        lg: `${closeMenu ? VERTICAL_NAV.closedWidth : VERTICAL_NAV.width}`,
      }}
      top={superAdmin ? 0 : 52}
      flex={1}
      px={4}
      minHeight={headerSize === 'small' ? LAYOUT.smallHeaderHeight : LAYOUT.largeHeaderHeight}
      maxHeight={headerSize === 'small' ? LAYOUT.smallHeaderHeight : LAYOUT.largeHeaderHeight}
      position="fixed"
      boxSizing="border-box"
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="border.main"
    >
      <Search
        fullWidth
        onChange={() => null}
        queryFunction={(searchTerm) => getCompanies('all', searchTerm, false)}
        ItemCard={(props) => <ItemCard {...props} />}
        optionLabelName="name"
      />

      {!account.super_admin && (
        <>
          <Link
            sx={{ m: 2 }}
            component={RouterLink}
            to={`/app/${
              account.users[0].Company.Type
            }s/${account.users[0].Company.company_id.toString()}`}
            underline="none"
            color="inherit"
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 1.5,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <IconWrapper
                icon="buildingOffice"
                sx={({ palette }) => ({
                  svg: {
                    color: palette.background.paper,
                    stroke: palette.primary.main,
                    strokeWidth: 2,
                  },
                })}
              />
              <Typography
                variant="button"
                sx={{ fontWeight: 700, fontSize: '0.625rem', lineHeight: 1.5 }}
              >
                Company
              </Typography>
            </Button>
          </Link>

          <Link
            component={RouterLink}
            to={`/app/users/${account.users[0].user_id.toString()}`}
            underline="none"
            color="inherit"
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 1.5,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <IconWrapper
                icon="user"
                sx={({ palette }) => ({
                  svg: {
                    color: palette.background.paper,
                    stroke: palette.primary.main,
                    strokeWidth: 2,
                  },
                })}
              />
              <Typography
                variant="button"
                sx={{ fontWeight: 700, fontSize: '0.625rem', lineHeight: 1.5 }}
              >
                Profile
              </Typography>
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};
