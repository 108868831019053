import * as yup from 'yup';

export const personalDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First name is required field'),
  lastName: yup.string().required('Last name is required field'),
  phoneNumber: yup.string().required('Phone number is required field'),
  companyEmail: yup.string().email('Invalid email').required('Company email is required field'),
  password: yup.string().required('Password is required field'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required field')
    .oneOf([yup.ref('password')], "Passwords does not match"),
});

export const personalDetailsSchemaBuyers = yup.object().shape({
  firstName: yup.string().required('First name is required field'),
  lastName: yup.string().required('Last name is required field'),
  phoneNumber: yup.string().required('Phone number is required field'),
  companyEmail: yup.string().email('Invalid email').required('Company email is required field'),
});
