import React from 'react';

import { Button, CircularProgress, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { FormProvider, Select, TextField, VATNumber } from 'components/Form';
import { Modal } from 'components/Modal';
import useYupValidationResolver from 'hook/useYupValidationResolver';

import { shadowCompanySchema } from '../validation';
import { useCreateShadowCompany } from '../api/createShadowCompany';
import { useAuth } from 'lib/auth';
import { validateVAT } from 'lib';

//  COUNTRIES SHOULD BE FETCHED FROM BACK END
const countries = [
  {
    label: 'Sweden',
    value: 'sweden',
  },
];

const getDefaultValues = () => ({
  Country: 'sweden',
  Name: '',
  VAT: '',
  vatNumberConfirmation: '',
});

export const ShadowCompany = ({ open, handleClose, addToList, type }) => {
  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(shadowCompanySchema),
  });

  const { user } = useAuth();
  const { mutate, isLoading } = useCreateShadowCompany();

  const onSubmit = async (formData) => {
    const isVATNumberValid = await validateVAT('SE', formData.VAT);
    if (isVATNumberValid) {
      mutate(
        { ...formData, Type: type, last_updated_user_id: user.user_id },
        {
          onSuccess: (data) => {
            if (addToList != null) {
              addToList(data);
            }
            
            onClose();
          },
        }
      );
    } else {
      methods.setError('VAT', { type: 'custom', message: 'Invalid VAT number' });
    }
  };

  const onClose = () => {
    methods.reset({ ...getDefaultValues() });
    handleClose();
  };

  const handleChange = (input) => (e) => {
    methods.setValue(input, e.target.value);
  }

  return (
    <Modal open={open} handleClose={onClose} title={`Add Shadow ${type[0].toUpperCase()}${type.slice(1)}`}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Stack gap={3}>
          <Select disabled required fullWidth name="Country" label="Country" options={countries} />
          <TextField required fullWidth name="Name" label="Company name" />
          <VATNumber
            name="VAT"
            label="VAT Number"
            required
            error={Boolean(methods?.formState?.errors?.VAT)}
            helperText={methods?.formState?.errors?.VAT?.message}
            onChange={handleChange("VAT")}
          />
          <VATNumber
            name="vatNumberConfirmation"
            label="Verify VAT number"
            required
            onChange={handleChange("vatNumberConfirmation")}
          />
          <Button variant="contained" color="primary" type="submit" sx={{ py: 1.5 }}>
            {!isLoading && 'Add shadow profile'}
            {isLoading && <CircularProgress color="inherit" size={20} />}
          </Button>
        </Stack>
      </FormProvider>
    </Modal>
  );
};
