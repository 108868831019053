import React, { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { paths } from 'routes';

import { Search } from 'components/Search';
import { BreadCrumbs } from 'components/Breadcrumbs';
import { ContentLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound/NotFound';
import { Pagination } from 'components/Pagination';
import { PremiumBanner } from '../../subscriptions/components/PremiumBanner';

import { useGetAll } from '../api/getAll';
import { useCount } from '../api/getCount';
import { ListItem } from '../components/ListItem';
import { Add } from '../components/Add';
import { useAuth } from 'lib/auth';
import { CheckboxFilter } from 'components/Filter/Inputs/CheckboxFilter';
import { OrderByDropdown } from 'components/Filter/Inputs/OrderByDropdown';
import { Loader } from 'components/Loader';

export const List = () => {
  const { account, user } = useAuth();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    includeInactive: false,
    includeExpired: false,
    sortBy: 'dateCreated',
  });
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const [additionalDPS, setAdditionalDPS] = useState(0);

  const {
    data: { dpses, total },
    refetch: refetchDPSes,
    isFetching: isFetching1,
  } = useGetAll({
    search,
    ...filters,
    page: page - 1,
    queryOptions: {
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      }
    },
  });

  const {
    //@ts-ignore
    data: { count },
    isFetching: isFetching2,
  } = useCount();

  const {
    //@ts-ignore
    data: { count: expiredCount },
    isFetching: isFetching3,
  } = useCount(true);

  const isFetching = isFetching1 || isFetching2 || isFetching3;

  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const handleFilterUpdate = ({ target: { name, value, checked } }) => {
    setFilters((filters) => {
      switch (name) {
        case 'sortBy':
          return { ...filters, [name]: value };

        default:
          return { ...filters, [name]: checked };
      }
    });
  };

  const handlePageChange = (_, page) => setPage(page);

  useRefetch();

  useEffect(() => {
    setAdditionalDPS(count + expiredCount - total);
  }, [total, count, expiredCount]);

  const caption = account.super_admin ? 'Click the "Add DPS" button to begin' : '';

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  return (
    <ContentLayout>
      <Stack height={1}>
        <Stack>
          <BreadCrumbs
            items={[
              { name: '', to: paths.dps },
              { name: `Dynamic Purchasing Systems${total > 0 ? ` (${total})` : ''}` }]}
          />
        </Stack>
        <Stack>
          
        </Stack>
        {!account.super_admin && (
          <>
          {(!user.Company.is_premium || user.Company.subscription_level === 'free') && (
            <PremiumBanner message={`There are ${additionalDPS} more Dynamic Purchasing Systems available for Premium members. Click here for more information`} handleSuccess={refetchDPSes} currentLevel={user.Company.subscription_level} />
          )}

          {(user.Company.is_premium && user.Company.subscription_level !== 'gold') && (
            <PremiumBanner message={`There are ${additionalDPS} more Dynamic Purchasing Systems available for Gold members. Click here for more information`} handleSuccess={refetchDPSes} currentLevel={user.Company.subscription_level} />
          )}
              
          </>
        )}

        <Stack
          mt={2}
          px={4}
          py={2}
          bgcolor="background.paper"
          borderTop="1px solid"
          borderColor="border.main"
          height="100%"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Search handleSearchChange={handleSearchChange} sx={{ minWidth: 200, width: 270 }} />
            <Stack direction="row" gap={2} sx={{ flex: '1' }}>
              <CheckboxFilter
                adminOnly
                name="includeInactive"
                onChange={handleFilterUpdate}
                checked={filters.includeInactive}
                label="Inactive"
              />
              <CheckboxFilter
                name="includeExpired"
                onChange={handleFilterUpdate}
                checked={filters.includeExpired}
                label="Expired"
                subscriptionTypes={['gold']}
                tooltip="Available only for Gold members"
              />
            </Stack>
            <OrderByDropdown
              name="sortBy"
              onChange={handleFilterUpdate}
              selected={filters.sortBy}
              options={[
                { value: 'dateCreated', text: 'Date Created' },
                {
                  value: 'expiringDate',
                  text: 'Expiring Date',
                  subscriptionType: ['silver', 'gold'],
                  tooltip: 'premium feature',
                },
              ]}
            />
            {account.super_admin && (
              <Add refetchDPSes={refetchDPSes} />
            )}
          </Stack>

          {isFetching && !loading ? 
          (
            <></>
          ) :
          (!isFetching || (isFetching && loading)) ?
          (
            <>
            {loading && (
              <Loader />
            )}

            {!loading && (
            <>
            {!dpses && (
              <Stack
                height="100%"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">No Dynamic Purchasing Systems Added</Typography>
                {account.super_admin && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 500,
                      fontSize: '0.875rem',
                      letterSpacing: '-0.14px',
                    }}
                  >
                    Click the "Add DPS" button to begin
                  </Typography>
                )}
              </Stack>
            )}

            {dpses && (
              <>
              <Stack
                flex={1}
                mt={2}
                py={2}
                bgcolor="background.paper"
                borderTop="1px solid"
                borderColor="border.main"
                gap={1}
              >
                {dpses.length === 0 && (
                  <NotFound
                    wrapperSx={{ mt: 19 }}
                    title="No Dynamic Purchasing Systems found"
                    caption={caption}
                  />
                )}
                {dpses.length !== 0 &&
                  dpses.map((item) => (
                    <ListItem key={item.dps_id} {...item} />
                  ))}
              </Stack>
            </>
          )}

            <Stack direction="row" justifyContent="center">
              <Pagination handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
            </>
          )}
          </>
        ) :
        (
          <></>
        )}
        </Stack>
      </Stack>
    </ContentLayout>
  );

  function useRefetch() {
    useEffect(() => {
      refetchDPSes();
    }, [page, filters, search]);
  }
};
