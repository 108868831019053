import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateFile = ({ dpsID, dcID = null, cgId = null, fileId, name }) => {
  let url = `/api/dps/${dpsID}/files/${fileId}`;
  if (dcID) {
    url = `/api/dps/${dpsID}/suppliers/${dcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/dps/${dpsID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  axios.put(url, { name });
}
  

export const useUpdateFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateFile,
    onSuccess: () => {
      enqueueSnackbar('File name was succesfully updated', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
