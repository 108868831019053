import React from 'react';

import { Box, Chip, SpeedDial, SpeedDialAction, Stack, Typography, Link } from '@mui/material';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { IconWrapper } from 'components/Icon';
import { useAuth } from 'lib/auth';

import { Link as RouterLink } from 'react-router-dom';
import { Status as StatusComponent } from 'components/Status';

export const BuyerListItem = ({
  Name: name,
  Status: status,
  VAT: vatNumber,
  markForDelete,
  isFirst,
  square_logo_url,
  company_id: buyerID,
}) => {
  const { account } = useAuth();
  return (
    <ListCard
      sx={({ palette }) =>
        isFirst
          ? {
              mb: 1,
              bgcolor: palette.grey[100],
            }
          : {}
      }
    >
      <Box display="flex" alignItems="center" flex={{ md: 5, lg: 6 }} gap={1.5}>
        <ImageAvatar img={square_logo_url || ''} name={name} />

        <Typography
          variant="body1"
          fontWeight={700}
          fontSize="1rem"
        >
          <Stack flex={{ md: 2, lg: 4 }} justifyContent="center">
            <Link
              component={RouterLink}
              to={`/app/buyers/${buyerID.toString()}`}
              color="inherit"
            >
              <Typography variant="body1" fontWeight={700}>
                {name}
              </Typography>
            </Link>
            <Stack direction="row" gap={1}>
              <Typography variant="caption" fontWeight={600}>
                {vatNumber}
              </Typography>
            </Stack>
          </Stack>
        </Typography>

        {isFirst && (
          <Chip
            color="info"
            label="Main Buyer"
            size="small"
            sx={{
              fontWeight: '700',
              fontSize: '0.625rem',
              letterSpacing: '-1%',
              lineHeight: 1.5,
              px: 1,
              py: 0.5,
            }}
          />
        )}
      </Box>

      {account.super_admin && (
      <>
        {isFirst && (
          <StatusComponent status={status} />
        )}
        {!isFirst && (
          <Box flex={0.5} position="relative">
            <StatusComponent status={status} />
            <SpeedDial
              direction="left"
              ariaLabel="Buyer actions"
              icon={
                <IconWrapper
                  icon="elipsisVertical"
                  sx={({ palette }) => ({
                    svg: { stroke: palette.grey[500], color: palette.grey[500] },
                  })}
                />
              }
              sx={({ palette }) => ({
                '& > button': {
                  boxShadow: 'none',
                  bgcolor: palette.background.paper,
                  width: 36,
                  height: 36,
                },
                '& > button:hover': { bgcolor: palette.background.paper },
                position: 'absolute',
                right: -14,
                top: 'calc(50% - 28px)',
                zIndex: 1,
              })}
            >
              <SpeedDialAction
                tooltipTitle="Delete"
                onClick={markForDelete}
                sx={({ palette }) => ({
                  bgcolor: palette.grey[50],
                  '&:hover': { bgcolor: palette.grey[200] },
                })}
                icon={
                  <IconWrapper
                    icon="trash"
                    sx={({ palette }) => ({
                      svg: {
                        width: 20,
                        height: 20,
                        stroke: palette.grey[500],
                        color: palette.grey[500],
                      },
                    })}
                  />
                }
              />
            </SpeedDial>
          </Box>
        )}
      </>
      )}
    </ListCard>
  );
};
