import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  queryKey: ['tender-consortium-group-files'],
  queryOptions: {},
};

const getTenderConsortiumGroupFiles = async (tenderID, cgId, queryStringParams, companyID) => {
  const queryString = qs.stringify({ ...queryStringParams });
  const {
    data: { data, total },
  } = await axios.get(`/api/tenders/${tenderID}/consortium_groups/${cgId}/files?${queryString}`, {headers: {"company_id": companyID}});

  return { files: data || [], total };
};

export const useTendersConsortiumGroupFiles = (tenderID, cgId, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions, limit, page, ...rest } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    page,
    offset: page * limit,
    ...rest,
  };

  return useQuery([...queryKey, cgId], () => getTenderConsortiumGroupFiles(tenderID, cgId, queryStringParams, user.Company.company_id), {
    initialData: { files: [], total: 0 },
    ...queryOptions,
  });
};