import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AccordionListItem } from 'components/Button';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { useUsersById } from '../api/getUserById';
import { Modal } from 'components/Modal';
import { Button, Stack } from '@mui/material';
import { FormProvider, TextField } from 'components/Form';
import { userValidationSchema } from '../validation';
import { useUpdateUserById } from '../api/updateUserById';

export const EditUser = () => {
  const { id: userId } = useParams();
  const [open, setOpen] = useState(false);

  const methods = useForm({
    defaultValues: {
      phoneNumber: '',
    },
    resolver: useYupValidationResolver(userValidationSchema),
  });
  const { refetch } = useUsersById(userId, {
    onSuccess: (data) => {
      methods.reset({
        phoneNumber: data?.phone_number || '',
        firstName: data?.first_name || '',
        lastName: data?.last_name || '',
      });
    },
  });

  const { mutateAsync } = useUpdateUserById(userId);

  const handleClose = useCallback(() => setOpen(false), []);
  const handleOpen = useCallback(() => setOpen(true), []);

  const onMutation = useCallback(() => {
    refetch();
    handleClose();
  }, []);
  const onSubmit = (values) => {
    mutateAsync({ ...values })
      .then(onMutation)
      .catch((error) => console.log(error));
  };

  return (
    <>
      <AccordionListItem onClick={handleOpen}>Edit</AccordionListItem>
      <Modal title="Edit user" handleClose={handleClose} open={open}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack gap={3} mb={3}>
            <TextField required fullWidth name="firstName" label="First Name" />
            <TextField required fullWidth name="lastName" label="Last Name" />
            <TextField required fullWidth name="phoneNumber" label="Phone Number" />
          </Stack>

          <Stack direction="row" gap={3} justifyContent="center">
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Update
            </Button>
          </Stack>
        </FormProvider>
      </Modal>
    </>
  );
};
