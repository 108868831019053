//@ts-nocheck

import React, { useEffect, useRef, useState } from 'react';

import { Modal } from 'components/Modal';

import { canvasPreview, centerAspectCrop, generateRandomString } from './helpers';
import { ImageUploadStepOne } from './StepOne';
import { ImageUploadStepTwo } from './StepTwo';

const defaultModalStyles = {
  p: 0,
  overflow: 'hidden',
  '& > .MuiStack-root': { gap: 0 },
  '.header-wrapper': { p: 4 },
  width: 800,
};

export const ImageUpload = ({
  title = 'Image upload',
  image = '',
  open,
  handleClose,
  uploadImage,
  aspect = 1,
  modalSx = {},
}) => {
  const [step, setStep] = useState(0);
  const [crop, setCrop] = useState(null);
  const [completeCrop, setCompleteCrop] = useState(null);
  const [imgSrc, setImgSrc] = useState(image);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);

  const onSelectFile = ({ target: { files } }) => {
    if (files && files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      setCompleteCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(files[0]);
    }
  };
  function onImageLoad({ currentTarget: { width, height } }) {
    if (aspect) {
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  async function handleUpload() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completeCrop) return;

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completeCrop.width * scaleX,
      completeCrop.height * scaleY
    );
    const ctx = offscreen.getContext('2d');
    if (!ctx) return;

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    });

    const name = generateRandomString();

    const file = new File([blob], name + '.png', { type: blob.type });

    const img = { file, name };

    //@ts-ignore
    uploadImage(img, {
      onSuccess: () => {
        handleClose();
        setStep(0);
        setCrop(null);
        setCompleteCrop(null);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  useDebounceEffect();
  useClearImage();
  useInitialImageChange();

  return (
    <Modal
      title={title}
      open={open}
      sx={{
        ...defaultModalStyles,
        ...modalSx,
      }}
      handleClose={handleClose}
    >
      <ImageUploadStepOne
        setCrop={setCrop}
        setCompleteCrop={setCompleteCrop}
        step={step}
        setStep={setStep}
        image={image}
        imgSrc={imgSrc}
        aspect={aspect}
        crop={crop}
        onImageLoad={onImageLoad}
        onSelectFile={onSelectFile}
        completeCrop={completeCrop}
        ref={imgRef}
      />

      <ImageUploadStepTwo
        step={step}
        completeCrop={completeCrop}
        setStep={setStep}
        handleUpload={handleUpload}
        ref={previewCanvasRef}
      />
    </Modal>
  );

  function useClearImage() {
    useEffect(() => {
      if (!open) {
        setStep(0);
        setImgSrc(image);
      }
    }, [open]);
  }

  function useInitialImageChange() {
    useEffect(() => {
      setImgSrc(image);
    }, [image]);
  }

  function useDebounceEffect() {
    useEffect(() => {
      const t = setTimeout(() => {
        (async () => {
          if (
            completeCrop?.width &&
            completeCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
          )
            canvasPreview(imgRef.current, previewCanvasRef.current, completeCrop);
        })();
      }, 100);

      return () => {
        clearTimeout(t);
      };
    }, [completeCrop]);
  }
};
