import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['company'],
  queryOptions: {},
};

const getTenderCompanyByTcID = async (tenderID, tcID, companyID) => {
  const { data } = await axios.get(`/api/tenders/${tenderID}/companies/${tcID}`, {headers: {"company_id": companyID}});

  return data;
};

export const useCompany = (tenderID, tcID, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, tcID], () => getTenderCompanyByTcID(tenderID, tcID, user.Company.company_id), {
    initialData: { company_name: 'Company', FA: {} },
    ...queryOptions,
  });
};
