import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const extractExtension = (name = '') => {
  const extensionDotIndex = name
    .split('')
    .reverse()
    .findIndex((char) => char === '.');

  if (!name || extensionDotIndex === -1) return null;

  return name.slice(-1 * extensionDotIndex);
};

const createFile = async (tenderID, cgId, { name, file, skipWatermark }) => {
  const formData = new FormData();
  file.fileName = file.name;
  formData.append('name', name);
  formData.append('file', file);
  formData.append('parent_id', cgId);
  formData.append('mime_type', file.type);
  formData.append('file_ext', extractExtension(file.name));
  formData.append('last_updated_user_id', '1');
  formData.append('parent_type', 'consortium_group');
  formData.append('skip_watermark', skipWatermark);

  const { data } = await axios.post(`/api/tenders/${tenderID}/consortium_groups/${cgId}/files`, formData);

  return data;
};

export const useCreateFile = (tenderID = 1, cgId = 1) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    //@ts-ignore
    mutationFn: (data) => createFile(tenderID, cgId, data),
    onSuccess: () => enqueueSnackbar('File succesfully uploaded', { variant: 'success' }),
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
