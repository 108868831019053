import React from 'react';
import { PremiumBanner } from './PremiumBanner';
import { useAuth } from 'lib/auth';

export const FilesPremiumBanner = ({ count = 0, sx= {}, handleSuccess = null }) => {
  const { user } = useAuth();
  let message = `There are ${count} files, available for Gold members. Click here for more information`;
  if (count === 1) {
    message = `There is ${count} file, available for Gold members. Click here for more information`;
  }
  return (
    <PremiumBanner
      message={message}
      handleSuccess={handleSuccess}
      currentLevel={user.Company.subscription_level}
      sx={{
        position:"relative",
        mt:'-16px',
        mx:'-32px',
        width: 'calc(100%+1%)',
        ...sx
      }}
    />
  );
};
