import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDeAJ5xtH0je1QCJXrWIzoenA-E6EBkeSQ",
    authDomain: "delegon.firebaseapp.com",
    projectId: "delegon",
    storageBucket: "delegon.appspot.com",
    messagingSenderId: "121078028354",
    appId: "1:121078028354:web:5b5d11f50b8e8c8ba0f5db"
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;