import React from 'react';
import DelegonGIF from 'assets/DELEGON ICON ACTION.gif';

export const Loader = ({style={}}) => {
  return (
    <img
      src={DelegonGIF}
      alt="Loading"
      style={{
        width:"50px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        ...style
      }}
    />
  );
};
