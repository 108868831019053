import React from 'react';
import { Box, Button } from '@mui/material';
import { IconWrapper } from 'components/Icon';

const EndIcon = ({color = "primary"}) => {
  let svgColor = 'primary.main';
  switch (color) {
    case "secondary":
      svgColor = "secondary.contrastText";
      break;
    default:
      break;
  }
  return (
    <IconWrapper
      icon="circlePlus"
      sx={{
        height: 24,
        mt: -0.5,
        svg: { color: svgColor, stroke: 'white', strokeWidth: 1.33 },
      }}
    />
  )
  };

export const AddButton = ({
  onClick,
  fullWidth = false,
  sx = {},
  CustomEndIcon = EndIcon,
  children,
  color = "primary",
}) => {
  return (
    <>
    <Box>
      <Button
        fullWidth={fullWidth}
        sx={sx}
        onClick={onClick}
        variant="contained"
        color={color}
        endIcon={<CustomEndIcon color={color} />}
      >
        {children}
      </Button>
    </Box>
    </>
  );
};
