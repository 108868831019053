//@ts-nocheck
import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib/axios';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  search: '',
  includeInactive: false,
  queryKey: ['companies'],
  companyType: "supplier",
  queryOptions: {},
};

const getAllCompanies = async (queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: companies, total },
  } = await axios.get(`/api/companies?${queryString}`);
  return {companies, total};
};

export const useCompanies = (queryParams = {}) => {
  const { queryKey, queryOptions, page, limit, search, includeInactive, companyType } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    search,
    includeinactive: includeInactive,
    offset: page * limit,
    companytype: companyType,
  };

  return useQuery([...queryKey, companyType], () => getAllCompanies(queryStringParams), {
    select: ({ companies, total }) => ({ companies, total }),
    initialData: {
      companies: [],
      total: 0,
    },
    ...queryOptions,
  });
};
