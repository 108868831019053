import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const removeConsortiumGroup = ({ frameworkAgreementId, cgId }) =>
  axios.delete(`/api/framework_agreements/${frameworkAgreementId}/consortium_groups/${cgId}`);

export const useRemoveConsortiumGroup = (onConsortiumGroupRemoval) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: removeConsortiumGroup,
    onSuccess: () => {
      enqueueSnackbar('Consortium group successfully removed', {
        variant: 'success',
      });
      onConsortiumGroupRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
