import React, { useState } from 'react';
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { IconWrapper } from 'components/Icon';

const minScale = 0.25;
const maxScale = 3;

const parseInputScale = (inputScale) => {
  if (+inputScale < minScale) return minScale;
  else if (+inputScale > maxScale) return maxScale;
  else return +inputScale;
};

const Toolbar = ({ close, handleDownload, scale, setScale }) => {
  return (
    <Stack
      position="relative"
      gap={4}
      direction="row"
      justifyContent="center"
      p={1}
      border="1px solid"
      sx={{ borderColor: 'grey.200' }}
    >
      <IconButton color="primary" onClick={handleDownload}>
        <IconWrapper icon="download" sx={{ mt: -0.625 }} />
      </IconButton>

      <Stack direction="row" alignItems="center">
        <IconButton
          disabled={scale <= minScale}
          onClick={() => {
            const parsedInputScale = parseInputScale(scale - 0.25);

            setScale(() => parsedInputScale);
          }}
        >
          <IconWrapper icon="minus" sx={{ mt: -0.625 }} />
        </IconButton>

        <IconButton
          disabled={scale >= maxScale}
          onClick={() => {
            const parsedInputScale = parseInputScale(scale + 0.25);

            setScale(() => parsedInputScale);
          }}
        >
          <IconWrapper icon="plus" sx={{ mt: -0.625 }} />
        </IconButton>

        <IconButton color="primary" onClick={close} sx={{ position: 'absolute', right: 16 }}>
          <IconWrapper icon="xMark" sx={{ mt: -0.625 }} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export const ImageViewer = ({ open, url, close, handleDownload }) => {
  const [scale, setScale] = useState(1);

  return (
    <Drawer anchor="top" open={open} onClose={close} sx={{ height: '100vh' }}>
      <Toolbar close={close} handleDownload={handleDownload} scale={scale} setScale={setScale} />

      <Stack
        gap={2}
        pt={2}
        sx={{
          overflow: 'hidden',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      ></Stack>
      <Box
        sx={({ spacing }) => ({
          height: `calc(100vh - ${spacing(4)})`,
          overflow: 'auto',
        })}
      >
        <Stack
          minHeight={1}
          maxHeight="200vh"
          justifyContent="center"
          alignItems="center"
          sx={{
            scrollbarWidth: 'thin',

            img: {
              width: 1000 * scale + 'px',
            },
          }}
        >
          <img src={url} />
        </Stack>
      </Box>
    </Drawer>
  );
};
