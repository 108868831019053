import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { InputWrapper } from './InputWrapper';
import { IconWrapper } from 'components/Icon';
import { useState } from 'react';

export function Select({
  name,
  native = false,
  maxHeight = 220,
  helperText = '',
  // children,
  PaperPropsSx = {},
  disabled = false,
  fullWidth = false,
  required = false,
  labelSx = {},
  wrapperSx = {},
  label = '',
  defaultValue={},
  options = [],
  ...other
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { control } = useFormContext();

  const handleToggle = () => setMenuOpen((open) => !open);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputWrapper
            disabled={disabled}
            error={!!error}
            label={label}
            fullWidth={fullWidth}
            required={required}
            labelSx={labelSx}
            wrapperSx={wrapperSx}
          >
            <TextField
              {...field}
              select
              fullWidth
              disabled={disabled}
              InputProps={{
                endAdornment: null,
              }}
              SelectProps={{
                defaultValue: defaultValue,
                native,
                onOpen: handleToggle,
                onClose: handleToggle,
                IconComponent: () => (
                  <IconWrapper
                    icon={menuOpen ? 'chevronUp' : 'chevronDown'}
                    sx={{ pr: 1.5, svg: { height: 20, width: 20 } }}
                  />
                ),
                MenuProps: {
                  PaperProps: {
                    sx: {
                      ...(!native && {
                        maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                      }),
                      ...PaperPropsSx,
                    },
                  },
                },
              }}
              error={!!error}
              helperText={error ? error?.message : helperText}
              {...other}
            >
              {options.map(({ label, value }, index) => (
                <MenuItem value={value} key={value + index}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputWrapper>
        );
      }}
    />
  );
}
