import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput } from 'components/Form';
import { useForm } from 'react-hook-form';
import { AccordionListItem } from 'components/Button';
import { useTender } from '../api/getTenderById';
import { useParams } from 'react-router-dom';
import useYupValidationResolver from 'hook/useYupValidationResolver';
import { additionalDetailsSchema } from '../validation';
import { useUpdateTender } from '../api/updateTender';

const getDefaultValues = () => ({
  tenderName: '',
  // refNumber: '',
  description: '',
  moreInfoURL: '',
  promoted: false,
  postingDate: null,
  lastOfferDate: null,
  startDate: null,
  endDate: null,
});

export const EditForm = ({tender, refetch}) => { 
  let { id } = useParams();

  const [open, setOpen] = useState(false);
  const [ promoted, setPromoted] = useState(tender?.promoted)
  const handlePromotedChange = () => {
    const newValue = !promoted;
    setPromoted(newValue);
    methods.setValue("promoted", newValue);
  }

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: useYupValidationResolver(additionalDetailsSchema),
  });

  const { mutateAsync } = useUpdateTender(refetch);

  const handleClose = () => {
    setOpen(false);
    methods.reset({ ...getDefaultValues() });
  };

  const onSubmit = (values) => {
    mutateAsync({
      ...values,
      mainBuyerId: tender?.mainBuyerId,
      tenderID: id,
    }).then(handleClose);
  };

  useEffect(() => {
    if (!open) {
      methods.reset({ ...getDefaultValues() });
    } else if (open) {
      const {
        tender_name,
        Description,
        more_info_url,
        end_date,
        mainBuyerId,
        start_date,
        posting_date,
        last_offer_date,
      } = tender;

      methods.reset({
        tenderName: tender_name,
        mainBuyerId: mainBuyerId,
        description: Description,
        moreInfoURL: more_info_url,
        postingDate: new Date(posting_date),
        lastOfferDate: new Date(last_offer_date),
        promoted: promoted,
        startDate: new Date(start_date),
        endDate: new Date(end_date),
      });
    
      setPromoted(tender.promoted)
    }
  }, [open]);

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: '#F3F4F6',
          justifyContent: 'center',
          display: open ? 'flex' : 'none',
          zIndex: 40,
          alignItems: 'center',
        }}
      >
        <Stack height={'100%'} width="100%">
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <PaperCard
              height={69}
              sx={{
                background: '#fff',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                px: 4,
                py: 2,
                border: 0,
                borderRadius: 0,
                boxShadow: 26,
                display: 'flex',
                boxSizing: 'border-box',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography>Editing ‘{tender?.tender_name}’</Typography>
                <Box
                  sx={{
                    width: 165,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="medium"
                    onClick={() => setOpen(false)}
                    variant="contained"
                    sx={({ palette }) => ({
                      width: 82,
                      boxShadow: 25,
                      color: palette.primary.main,
                      backgroundColor: '#fff',
                    })}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ width: 67 }}
                  >
                    Save
                  </Button>
                </Box>
              </Stack>
            </PaperCard>

            <Stack
              direction="row"
              width="100%"
              height="100vh"
              mt={26.25}
              bgcolor={'#fff'}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={'50%'} direction="column" px={8} py={4}>
                <FormGroup sx={{ 
                  flex: '1 1 50',
                  alignItems:"baseline",
                  '& .MuiFormControlLabel-root': {
                    m:0
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize:"0.75rem"
                  },
                }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={promoted}
                        onChange={handlePromotedChange}
                        name="promoted"
                      />
                    }
                    label="Promoted"
                    labelPlacement="top"
                  />
                </FormGroup>
                <TextField
                  name="tenderName"
                  label="Tender Name"
                  placeholder="Please enter Tender name"
                  required={true}
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Please enter description"
                  required={true}
                  type="text"
                  fullWidth
                  sx={{
                    mb: 2,
                    '& .MuiInputBase-root, .MuiInputBase-input': {
                      p: 0,
                    },
                  }}
                  multiline
                  minRows={2}
                />
              </Stack>

              <Stack
                width={'50%'}
                direction="column"
                px={8}
                py={4}
                gap={2}
              >
                <Stack
                  width={'100%'}
                  direction="row"
                  gap={2}
                >
                  <DateInput
                    name="postingDate"
                    label="Posting Date"
                    required
                    wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                  <DateInput
                    name="lastOfferDate"
                    label="Last Offer Date"
                    required wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                </Stack>
                <Stack
                  width={'100%'}
                  direction="row"
                  gap={2}
                >
                  <DateInput
                    name="startDate"
                    label="Start Date"
                    required
                    wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                  <DateInput
                    name="endDate"
                    label="End Date"
                    required wrapperSx={{ mb: 2 }}
                    fullWidth
                  />
                </Stack>
                <TextField
                  name="moreInfoURL"
                  label="More Information URL"
                  placeholder="Please enter URL with more information"
                  required={true}
                  type="text"
                  sx={{ mb: 2 }}
                  fullWidth
                />
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Box>
    </>
  );
};
