import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateName = async (tenderID, cgId, cg_name) =>
  axios.put(
    `/api/tenders/${tenderID}/consortium_groups/${cgId}`,
    JSON.stringify({ cg_name })
  );
const addSuppliersToConsortiumGroup = async (tenderID, cgId, companyIds) =>
  axios.post(
    `/api/tenders/${tenderID}/consortium_groups/${cgId}/companies`,
    JSON.stringify({
      tender_id: +tenderID,
      cg_id: +cgId,
      companies_ids: companyIds,
      company_type: 'supplier',
    })
  );
const removeSupplierFromConsortiumGroup = async (tenderID, cgId, cgcId) =>
  axios.delete(`/api/tenders/${tenderID}/consortium_groups/${cgId}/companies/${cgcId}
`);

const updateConsortiumGroup = async ({
  tenderID,
  cgId,
  cg_name = '',
  removedSuppliers = [],
  addedSuppliers = [],
}) => {
  return Promise.all([
    cg_name ? updateName(tenderID, cgId, cg_name) : null,
    addedSuppliers.length ? addSuppliersToConsortiumGroup(tenderID, cgId, addedSuppliers) : null,
    ...removedSuppliers.map((cgcId) => removeSupplierFromConsortiumGroup(tenderID, cgId, cgcId)),
  ]);
};

export const useUpdateConsortiumGroup = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateConsortiumGroup,
    onSuccess: () => {
      enqueueSnackbar('Consortium group was succesfully updated', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      //   refetch();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
