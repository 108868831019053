import React, { useEffect, useRef, useState } from 'react';

import { Document, Page, Thumbnail, pdfjs } from 'react-pdf';
import {
  Box,
  Drawer,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IconWrapper } from 'components/Icon';
import { Loader } from 'components/Loader';

import 'react-pdf/dist/Page/TextLayer.css';

const minScale = 0.25;
const maxScale = 3;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

const parseInputScale = (inputScale) => {
  if (+inputScale < 100 * minScale) return minScale * 100;
  else if (+inputScale > maxScale * 100) return maxScale * 100;
  else return +inputScale;
};
const parsePage = (page, pageNumber) => {
  if (+page > pageNumber) return pageNumber;
  else return +page || 1;
};

const PDFToolbar = ({
  close,
  handleDownload,
  currentPage,
  setCurrentPage,
  setInputCurrentPage,
  inputCurrentPage,
  pageNumber,
  scale,
  inputScale,
  setScale,
  setInputScale,
}) => (
  <Stack
    position="relative"
    gap={4}
    direction="row"
    justifyContent="center"
    p={1}
    border="1px solid"
    sx={{ borderColor: 'grey.200' }}
  >
    <IconButton color="primary" onClick={handleDownload}>
      <IconWrapper icon="download" sx={{ mt: -0.625 }} />
    </IconButton>
    <Stack direction="row" gap={1} alignItems="center">
      <IconButton
        color="primary"
        disabled={currentPage === 1}
        onClick={() => {
          setCurrentPage((currentPage) => currentPage - 1);
          setInputCurrentPage((inputCurrentPage) => inputCurrentPage - 1);
        }}
      >
        <IconWrapper icon="chevronLeft" sx={{ mt: -0.625 }} />
      </IconButton>
      <Stack direction="row" gap={0.5} alignItems="center">
        <Typography variant="caption" textAlign="center">
          Page
        </Typography>
        <TextField
          variant="standard"
          defaultValue={1}
          value={inputCurrentPage}
          onChange={({ target: { value } }) => {
            if (isNaN(+value)) return;
            setInputCurrentPage(+value);
          }}
          onBlur={({ target: { value } }) => {
            const parsedPage = parsePage(value, pageNumber);

            setCurrentPage(parsedPage);
            setInputCurrentPage(parsedPage);
          }}
          //@ts-ignore
          onKeyDown={({ key, target: { value } }) => {
            if (key === 'Enter') {
              const parsedPage = parsePage(value, pageNumber);
              setCurrentPage(parsedPage);
              setInputCurrentPage(parsedPage);
            }
          }}
          sx={{ width: 65 }}
        />
        <Typography variant="caption" textAlign="center">
          of {pageNumber}
        </Typography>
      </Stack>

      <IconButton
        color="primary"
        disabled={currentPage === pageNumber}
        onClick={() => {
          setCurrentPage((currentPage) => currentPage + 1);
          setInputCurrentPage((inputCurrentPage) => inputCurrentPage + 1);
        }}
      >
        <IconWrapper icon="chevronRight" sx={{ mt: -0.625 }} />
      </IconButton>
    </Stack>

    <Stack direction="row" alignItems="center">
      <IconButton
        disabled={scale <= minScale}
        onClick={() => {
          const parsedInputScale = parseInputScale(inputScale - 25);

          setScale(() => parsedInputScale / 100);
          setInputScale(() => parsedInputScale);
        }}
      >
        <IconWrapper icon="minus" sx={{ mt: -0.625 }} />
      </IconButton>

      <TextField
        onBlur={({ target: { value } }) => {
          const parsedInputScale = parseInputScale(value);

          setInputScale(parsedInputScale);
          setScale(parsedInputScale / 100);
        }}
        variant="standard"
        defaultValue={100}
        value={inputScale}
        InputProps={{
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        onChange={({ target: { value } }) => {
          if (+value < 0 || isNaN(+value)) return;
          else setInputScale(+value);
        }}
        //@ts-ignore
        onKeyDown={({ key, target: { value } }) => {
          if (key === 'Enter') {
            const parsedInputScale = parseInputScale(value);
            setInputScale(parsedInputScale);
            setScale(parsedInputScale / 100);
          }
        }}
        sx={{ width: 80 }}
      />
      <IconButton
        disabled={scale >= maxScale}
        onClick={() => {
          const parsedInputScale = parseInputScale(inputScale + 25);

          setScale(() => parsedInputScale / 100);
          setInputScale(() => parsedInputScale);
        }}
      >
        <IconWrapper icon="plus" sx={{ mt: -0.625 }} />
      </IconButton>

      <IconButton color="primary" onClick={close} sx={{ position: 'absolute', right: 16 }}>
        <IconWrapper icon="xMark" sx={{ mt: -0.625 }} />
      </IconButton>
    </Stack>
  </Stack>
);

export const PDFViewer = ({ open, url, close, handleDownload }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputCurrentPage, setInputCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [inputScale, setInputScale] = useState(100);

  const ref = useRef(null);

  const handleScroll = () => ref.current.scrollIntoView();

  useEffect(() => {
    if (ref?.current) handleScroll();
  }, [currentPage]);

  useEffect(() => {
    setPageNumber(1);
    setCurrentPage(1);
  }, [open]);

  return (
    <Drawer anchor="top" open={open} onClose={close} sx={{ height: '100vh' }}>
      <Stack width={1} height="100vh" maxHeight="100vh">
        <PDFToolbar
          close={close}
          currentPage={currentPage}
          handleDownload={handleDownload}
          inputCurrentPage={inputCurrentPage}
          inputScale={inputScale}
          pageNumber={pageNumber}
          scale={scale}
          setCurrentPage={setCurrentPage}
          setInputCurrentPage={setInputCurrentPage}
          setInputScale={setInputScale}
          setScale={setScale}
        />
        <Stack
          gap={2}
          pt={2}
          sx={{
            overflow: 'hidden',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: 1,
              position: 'relative',
              scrollbarWidth: 'thin',
              '.page': {
                '& > canvas': {
                  margin: '0 auto',
                  height: { xs: 'inherit !important' },
                  width: 'inherit !important',
                },
              },
            }}
          >
            <Document
              externalLinkTarget="_blank"
              className="document"
              file={url}
              loading={<Loader style={{ marginTop: "40vh"}} />
              }
              onLoadSuccess={({ numPages }) => {
                setPageNumber(numPages);
              }}
            >
              <Stack
                direction="row"
                gap={1}
                sx={({ palette }) => ({
                  '& *': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: `${palette.grey[300]} white`,
                    '::-webkit-scrollbar': {
                      width: '60px',
                      height: '60px',
                      borderRadius: 2.5,
                      borderColor: 'red !important',
                    },
                    '::-webkit-scrollbar-thumb': {
                      backgroundColor: 'red',
                      border: '4px solid transparent',
                      borderRadius: '8px',
                      backgroundClip: 'padding-box',
                    },
                  },
                })}
              >
                <Stack
                  gap={4}
                  minWidth={180}
                  sx={({ spacing }) => ({
                    overflowY: 'auto',
                    maxHeight: `calc(100vh - ${spacing(4)})`,
                  })}
                >
                  {Array.from(new Array(pageNumber), (_, pageIndex) => (
                    <Stack key={pageIndex} gap={2} alignItems="center">
                      <Box
                        border="5px solid"
                        borderRadius={0.5}
                        sx={({ palette }) => ({
                          borderColor:
                            pageIndex + 1 === currentPage
                              ? palette.primary.main
                              : palette.background.paper,
                        })}
                      >
                        <Thumbnail
                          scale={0.25}
                          pageIndex={pageIndex}
                          onItemClick={({ pageNumber }) => {
                            setCurrentPage(pageNumber);
                            setInputCurrentPage(pageNumber);
                          }}
                        />
                      </Box>

                      <Typography variant="caption">{pageIndex + 1}</Typography>
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  flex={1}
                  maxHeight={1}
                  sx={({ spacing }) => {
                    return {
                      overflowY: 'auto',
                      maxHeight: `calc(100vh - ${spacing(4)})`,

                      '.react-pdf__Page__textContent': {
                        margin: 'auto',
                      },
                    };
                  }}
                >
                  {Array.from(new Array(pageNumber), (_, index) => (
                    <Page
                      inputRef={index + 1 === currentPage && ref}
                      key={index}
                      scale={scale < 0.25 ? 0.25 : scale > 3 ? 3 : scale}
                      className="page"
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                    />
                  ))}
                </Stack>
              </Stack>
            </Document>
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  );
};
