import React from 'react';

import { Autocomplete, CircularProgress, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';

import { InputWrapper } from './InputWrapper';

import InputAdornment from '@mui/material/InputAdornment';
import { IconWrapper } from 'components/Icon';

//  THE PROPERTY optionLabelName IS REQUIRED AS THE AUTOCOMPLETE COMPONENT
//  REQUIRES IT'S ITEM OBJECT TO HAVE THE LABEL PROPERTY AS AN

//  !!! UNIQUE !!!

//  IDENTIFIER. IT (optionLabelName) MAPPS SOME EXISTING PROPERTY OF THE ITEM OBJECT
//  TO THE LABEL

export const Search = ({
  name = '',
  label = '',
  fullWidth = false,
  wrapperSx = {},
  onChange,
  ItemCard,
  handleNotFoundButtonClick = () => null,
  queryFunction,
  optionLabelName,
  noOptionFound = 'Cannot find Organization for provided search pattern',
}) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { data, refetch } = useQuery([name, inputValue], () => queryFunction(inputValue), {
    enabled: !!inputValue,
    initialData: { data: [] },
    select: ({ data }) => {
      return data.map((item) => ({
        ...item,
        label:
          typeof item[optionLabelName] === 'number'
            ? item[optionLabelName].toString()
            : item[optionLabelName],
      }));
    },
    onSettled: () => {
      setLoading(() => false);
    },
  });

  const debouncedSetInputValue = useDebouncedCallback(
    (newInputValue) => setInputValue(() => newInputValue),
    300
  );
  useEffect(() => {
    if (inputValue) refetch();
    // eslint-disable-next-line
  }, [inputValue]);

  return (
    <InputWrapper fullWidth={fullWidth} wrapperSx={wrapperSx}>
      <Autocomplete
        forcePopupIcon={false}
        value={null}
        blurOnSelect={true}
        options={data}
        getOptionLabel={(item) => item.label}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          event.preventDefault();
        }}
        open={!loading && inputValue.length >= 1}
        onInputChange={(_, newInputValue) => {
          setLoading(() => !!newInputValue);
          debouncedSetInputValue(newInputValue);
        }}
        noOptionsText={inputValue ? noOptionFound : ''}
        renderOption={(props, option) => <ItemCard {...{ ...props, ...option }} />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Organizations"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconWrapper icon="search" sx={{ svg: { height: 20 } }} />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading && <CircularProgress color="primary" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={{
              '.MuiInputBase-root': {
                backgroundColor: 'white',
                p: 0,
                borderRadius: 12.5,
                width: 350,
                height: 52,
                minWidth: 200,
                pl: 2,
                pr: 1,
              },
              '.MuiInputBase-input': { px: '0px!important' },
            }}
          />
        )}
        ListboxProps={{
          sx: {
            p: 0,
            boxShadow: 25,
            borderWidth: 2,
            '& > *': {
              p: '16px !important',
              bgcolor: 'white !important',
            },
            '& > *:hover': { bgcolor: 'white !important' },
            '& > :not(:last-child)': {
              borderBottom: '2px solid',
              borderColor: 'border.main',
            },
          },
        }}
        componentsProps={{
          popper: {
            sx: {
              '.MuiAutocomplete-paper': {
                width: 500,
              },
            },
          },
        }}
      />
    </InputWrapper>
  );
};
