import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const updateFile = ({ tenderID, tcID = null, cgId = null, fileId, name }) => {
  let url = `/api/tenders/${tenderID}/files/${fileId}`;
  if (tcID) {
    url = `/api/tenders/${tenderID}/suppliers/${tcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/tenders/${tenderID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  axios.put(url, { name });
}
  

export const useUpdateTenderFile = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: updateFile,
    onSuccess: () => {
      enqueueSnackbar('File name was succesfully updated', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
