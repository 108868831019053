import React from 'react';
import { Stack } from '@mui/material';
import { IconWrapper } from 'components/Icon';
import { Avatar } from './Avatar';

export const ImageAvatar = ({ img = '', name = '', searchResult = false }) => {
  if (!img) return <Avatar name={name} searchResult={searchResult} />;

  return (
    <Stack
      alignSelf="center"
      justifyContent="center"
      alignItems="center"
      sx={{
        border: 'none !Important',
        bgcolor: 'grey.300',
        borderRadius: '100%',
        width: searchResult ? 25 : 40,
        height: searchResult ? 25 : 40,
        img: {
          width: searchResult ? 25 : 40,
          height: searchResult ? 25 : 40,
          borderRadius: '100%',
          overflow: 'hidden',
        },
      }}
    >
      {img && <img src={img} />}
      {!img && (
        <IconWrapper
          icon="photo"
          sx={{ svg: { width: 20, height: 20, stroke: 'white', color: 'white' } }}
        />
      )}
    </Stack>
  );
};
