import { axios } from "lib/axios"

export const requestAccess = async (data, message) => {
  try {
    await sendRequest(data, message);
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      throw new Error("Account already exists.");
    } else if (error.code === 'auth/weak-password') {
      throw new Error("Password is too weak.");
    } else if (error.code === 'auth/invalid-email') {
      throw new Error("Invalid e-mail address");
    } else {
      throw error;
    }
  }
}

const sendRequest = async (data, message) => {
  const jsonData = {
    "account": {
      "email": data.companyEmail,
      "status": "access_requested",
      "firebase_uid": data.firebaseUID ? data.firebaseUID : null,
    },
    "user": {
      "first_name": data.firstName,
      "last_name": data.lastName,
      "phone_number": data.phoneNumber,
      "email": data.companyEmail,
      "role": "company_admin",
      "status": "access_requested",
      "title": "Administrator",
    },
    "company": {
      "company_id": data?.companyID,
      "name": data.companyName,
      "country": data.country,
      "vat": data.vat,
    },
    "message": message,
  }

  return await axios.post("/auth/request-access", JSON.stringify(jsonData));
}

