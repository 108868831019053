//@ts-nocheck

import React, { forwardRef } from 'react';
import { Button, Stack } from '@mui/material';

export const ImageUploadStepTwo = forwardRef(
  ({ step, completeCrop, setStep, handleUpload }, previewCanvasRef) => (
    <Stack gap={3} visibility={step === 1 ? 'visible' : 'hidden'} height={step === 1 ? 'auto' : 0}>
      <Stack
        justifyContent="center"
        alignItems="center"
        height={step === 1 ? 600 : 0}
        sx={{
          canvas: {
            objectFit: 'contain',
            width: completeCrop?.width || 0,
            height: completeCrop?.height || 0,
          },
        }}
      >
        <canvas ref={previewCanvasRef} />
      </Stack>

      <Stack direction="row" px={4} pb={4} justifyContent="space-between">
        <Button variant="outlined" color="primary" onClick={() => setStep(0)}>
          Select Another
        </Button>

        <Button variant="contained" color="primary" onClick={handleUpload}>
          Upload Image
        </Button>
      </Stack>
    </Stack>
  )
);
