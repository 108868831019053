import { axios } from "lib/axios"

export const signUp = async (data) => {
  try {
    await signUpRequest(data);
  } catch (error) {
    throw error;
  }
}

const signUpRequest = async (data) => {
  const jsonData = {
    "user_name": `${data.firstName} ${data.lastName}`,
    "phone_number": data.phoneNumber,
    "email": data.companyEmail,
  }

  return await axios.post("/auth/signup", JSON.stringify(jsonData));
}

