import Button from './components/button';
import TextField from './components/textfield';
import MuiStepIcon from './components/stepicon';

const overrideStyles = (theme) => ({
  ...Button(theme),
  ...TextField(theme),
  ...MuiStepIcon(theme),
});

export default overrideStyles;
