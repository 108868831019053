import { Stack, Typography } from '@mui/material';
import { forwardRef } from 'react';

export const Snackbar = forwardRef((props, ref) => {
  const { message, variant } = props;

  return (
    <Stack
      ref={ref}
      direction="row"
      bgcolor="background.paper"
      borderRadius={1}
      boxShadow={27}
      borderLeft="32px solid"
      borderColor={`${variant}.light`}
      px={4}
      py={1}
    >
      <Typography variant="body1" fontWeight="bold">
        {message}
      </Typography>
    </Stack>
  );
});
