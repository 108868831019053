import { axios } from 'lib';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const defaultQueryParams = {
  queryKey: ['company'],
  queryOptions: {},
};

const getFACompanyByFacID = async (faId, facId, companyID) => {
  const { data } = await axios.get(`/api/framework_agreements/${faId}/companies/${facId}`, {headers: {"company_id": companyID}});

  return data;
};

export const useCompany = (faId, facId, queryParams = {}) => {
  const { user } = useAuth();
  const { queryKey, queryOptions } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  return useQuery([...queryKey, facId], () => getFACompanyByFacID(faId, facId, user.Company.company_id), {
    initialData: { company_name: 'Company', FA: {} },
    ...queryOptions,
  });
};
