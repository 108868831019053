import { useSnackbar } from 'notistack';

import { axios } from 'lib';
import { useMutation } from 'react-query';

const removeRemoveFile = async ({ tenderID, tcID = null, cgId = null, fileId }) => {
  let url = `/api/tenders/${tenderID}/files/${fileId}`;
  if (tcID) {
    url = `/api/tenders/${tenderID}/suppliers/${tcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/tenders/${tenderID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  axios.delete(url);
}
  

export const useRemoveFile = (onFileRemoval) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: removeRemoveFile,
    onSuccess: () => {
      enqueueSnackbar('The file was succesfully deleted', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      onFileRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
