import React from 'react';

import { Box, Chip, Link, SpeedDial, SpeedDialAction, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Avatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { IconWrapper } from 'components/Icon';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';

import { useFrameworkAgreementsConsortiumGroupFilesCount } from '../api/getFrameworkAgreementsConsortiumGroupFilesCount';

export const ConsortiumGroupListItem = ({
  cg_name: name,
  markForDelete,
  cg_id,
  fa_id,
  fac_id,
  supplierCount,
}) => {
  const { account } = useAuth();
  const {
    //@ts-ignore
    data: { count: filesCount },
  } = useFrameworkAgreementsConsortiumGroupFilesCount(fa_id, fac_id);
  return (
    <ListCard>
      <Box display="flex" alignItems="center" flex={5} gap={1.5}>
        <Avatar name={name} />

        <Typography
          variant="body1"
          fontWeight={700}
          fontSize="1rem"
        >
          {name}
        </Typography>
        <Chip
          label={'Group'}
          sx={{
            bgcolor: 'white',
            // textTransform: 'capitalize',
            px: 1,
            py: 0.5,
            fontWeight: 'bold',
            // border: '1px solid white',
            boxShadow: 25,
            fontSize: '0.75rem',
          }}
        />
      </Box>
      <Stack justifyContent="center" height={1} flex={0.5}>
        <Typography variant="caption" fontWeight={700} color="text.secondary">
          Suppliers
        </Typography>
        <Link
          component={RouterLink}
          to={`${paths.frameworkAgreementConsortiumGroups(fa_id, cg_id)}`}
          color="inherit"
        >
          <Typography variant="body2" fontWeight={700}>
            {supplierCount}
          </Typography>
        </Link>
      </Stack>
      <Box flex={account.super_admin ? 0.5 : 0.4} position="relative">
        <Stack justifyContent="center" flex={1}>
          <Typography variant="caption" fontWeight={700} color="text.secondary">
            Files
          </Typography>
          <Link
            component={RouterLink}
            to={`${paths.frameworkAgreementConsortiumGroupsFiles(fa_id, fac_id)}`}
            color="inherit"
          >
            <Typography variant="body2" fontWeight={700}>
              {filesCount}
            </Typography>
          </Link>
        </Stack>
          {account.super_admin && (
            <SpeedDial
              onClick={(e) => e.preventDefault()}
              direction="left"
              ariaLabel="Buyer actions"
              icon={
                <IconWrapper
                  icon="elipsisVertical"
                  sx={({ palette }) => ({
                    svg: { stroke: palette.grey[500], color: palette.grey[500] },
                  })}
                />
              }
              sx={({ palette }) => ({
                '.MuiSpeedDial-actions': { bgcolor: 'white', borderRadius: 1 },
                '.MuiSpeedDial-actionsClosed': { bgcolor: 'transparent' },
                '& > button': {
                  boxShadow: 'none',
                  bgcolor: palette.background.paper,
                  width: 36,
                  height: 36,
                },
                '& > button:hover': { bgcolor: palette.background.paper },
                position: 'absolute',
                right: -14,
                top: 'calc(50% - 28px)',
                zIndex: 1,
              })}
            >
              <SpeedDialAction
                tooltipTitle="Delete"
                onClick={markForDelete}
                sx={({ palette }) => ({
                  bgcolor: palette.grey[50],
                  '&:hover': { bgcolor: palette.grey[200] },
                })}
                icon={
                  <IconWrapper
                    icon="trash"
                    sx={({ palette }) => ({
                      svg: {
                        width: 20,
                        height: 20,
                        stroke: palette.grey[500],
                        color: palette.grey[500],
                      },
                    })}
                  />
                }
              />
            </SpeedDial>
          )}
      </Box>
    </ListCard>
  );
};
