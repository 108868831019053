//@ts-nocheck
import { useQuery } from 'react-query';
import qs from 'qs';

import { axios } from 'lib/axios';

const defaultQueryParams = {
  page: 0,
  limit: 10,
  queryKey: ['subscriptions'],
  queryOptions: {},
};

const getAllSubscriptions = async (queryStringParams) => {
  const queryString = qs.stringify({ ...queryStringParams });

  const {
    data: { data: subscriptions, total },
  } = await axios.get(`/api/subscriptions?${queryString}`);
  return {subscriptions, total};
};

export const useSubscriptions = (queryParams = {}) => {
  const { queryKey, queryOptions, page, limit, companyType } = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const queryStringParams = {
    limit,
    offset: page * limit,
  };

  return useQuery([...queryKey, companyType], () => getAllSubscriptions(queryStringParams), {
    select: ({ subscriptions, total }) => ({ subscriptions, total }),
    initialData: {
      subscriptions: [],
      total: 0,
    },
    ...queryOptions,
  });
};
