import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';


const getFrameworkAgreementFilesCount = (frameworkAgreemenId, companyID) => {
  return axios.get(
    `/api/framework_agreements/${frameworkAgreemenId}/files/count`,
    {headers: {"company_id": companyID}}
  );
};

export const useFrameworkAgreementFilesCount = (frameworkAgreementId, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `framework-agreement-${frameworkAgreementId}-count`,
    () => getFrameworkAgreementFilesCount(frameworkAgreementId, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
};