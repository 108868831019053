import React from 'react';
import { Box, Breadcrumbs, Link, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { IconWrapper } from 'components/Icon';

const BreadCrumb = ({ name, to, isLast }) => {
  const styles = {
    alignItems: 'center',
    color: 'text.secondary',
    fontWeight: 500,
    // fontSize: '0.5625rem',
    fontSize: '0.75rem',
    display: 'flex',
    lineHeight: '130%',
    letterSpacing: '-0.09px',
    ...(isLast && {
      cursor: 'default',
      pointerEvents: 'none',
      color: 'text.primary',
      textDecoration: 'none',
      fontWeight: 700,
      fontSize: '0.825rem',
    }),
  };

  return (
    <Link component={RouterLink} to={to} sx={styles}>
      {name}
    </Link>
  );
};

export const BreadCrumbs = ({ items = [], sx = {} }) => {
  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {!!items.length && (
            <Breadcrumbs
              separator={
                <IconWrapper
                  icon="chevronRight"
                  sx={{
                    svg: {
                      width: 14,
                      height: 14,
                      color: 'grey.300',
                      stroke: 'grey.300',
                    },
                  }}
                />
              }
            >
              {items.map((item, index) => (
                <BreadCrumb key={item.to || index} isLast={index === items.length - 1} {...item} />
              ))}
            </Breadcrumbs>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
