import React, { useEffect, useState } from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { NotFound } from 'components/NotFound/NotFound';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'components/Search';
import { Pagination } from 'components/Pagination';

import { useFrameworkAgreementFiles } from '../api/getFrameworkAgreementFiles';
import { FileListItem } from './FileListItem';
import { downloadFile, donwloadFileFromURL } from '../api/downloadFile';
import { FileUploadModal } from './FileUploadModal';
import { useFilePreview } from '../api/previewFile';
import { PDFViewer } from 'components/PDFViewer/PDFViewer';
import { DeleteConfirmationModal } from './DeleteConfirmationModal';
import { useRemoveFile } from '../api/removeFile';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { FilesPremiumBanner } from '../../subscriptions/components/FilesPremiumBanner';
import { Loader } from 'components/Loader';

import { useAuth } from 'lib/auth';


const filesTabName = 'files';

export const FilesTab = ({ currentTab }) => {
  const { id } = useParams();
  const { account, user } = useAuth();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [currentFilePreviewId, setCurrentFilePreviewId] = useState(null);
  const [markedForDelete, setMarkedForDelete] = useState(null);
  const [markedForEdit, setMarkedForEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);

  const {
    data: { files, total },
    refetch: refetchFiles,
    isFetching,
  } = useFrameworkAgreementFiles(id, {
    search,
    page: page - 1,
    queryOptions: { 
      onSettled: () => {
        clearTimeout(timer);
        setTimeout(() => setLoading(false), 1000);
      },
      enabled: currentTab === filesTabName },
  });
  const {
    data: { url, name, MimeType },
  } = useFilePreview(id, currentFilePreviewId);

  const handlePageChange = (_, page) => setPage(page);
  const debouncedSearchChange = useDebouncedCallback((search) => setSearch(search), 300);
  const handleSearchChange = ({ target: { value: search } }) => {
    debouncedSearchChange(search);
  };

  const onFileRemoval = () => {
    refetchFiles();
    setMarkedForDelete(null);
  };
  const handleMutation = () => {
    refetchFiles();
    if (markedForEdit) setMarkedForEdit(null);
  };

  const { mutateAsync: removeFile } = useRemoveFile(onFileRemoval);

  useTabReset();
  useRefetch();

  useEffect(() => {
    if (isFetching) {
      const timeoutId = setTimeout(() => setLoading(true), 500);
      setTimer(timeoutId);
    } else {
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isFetching]);

  if (currentTab !== filesTabName) return null;

  if (!account.super_admin && (!user.Company.is_premium || user.Company.subscription_level !== 'gold')) {
    if (loading) {
      return <Loader />
    }

    return <FilesPremiumBanner count={total} />;
  }

  return (
    <>
      <ImageViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('image/')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />

      <PDFViewer
        url={url}
        open={!!currentFilePreviewId && MimeType.includes('application/pdf')}
        close={() => setCurrentFilePreviewId(null)}
        handleDownload={() => donwloadFileFromURL(url, name)}
      />
      {account.super_admin && (
        <DeleteConfirmationModal
          title={'Remove file'}
          markedForDelete={markedForDelete}
          mutateAsync={() => removeFile({ faId: id, fileId: markedForDelete?.itemId })}
        >
          <Typography variant="body1" fontWeight={500}>
            Please confirm the removal of the file{' '}
            <span style={{ fontWeight: 'bold' }}>{markedForDelete?.name}.</span>
          </Typography>
        </DeleteConfirmationModal>
      )}

      <Stack gap={2} height={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} width={1}>
          <Search fullWidth handleSearchChange={handleSearchChange} sx={{minWidth: 200, width: 270}}/>
          {account.super_admin && (
            <Box sx={{minWidth: 150, width: 200}}>
              <FileUploadModal handleSuccess={handleMutation} markedForEdit={markedForEdit} />
            </Box>
          )}
        </Stack>

        <Divider />

        {isFetching && !loading ? 
        (
          <></>
        ) :
        (!isFetching || (isFetching && loading)) ?
        (
          <>
          {loading && (
            <Loader />
          )}

          {!loading && (
            <>
            <Stack flex={1} gap={1}>
              {files?.length === 0 && (
                <NotFound
                  title="No Files Found"
                  caption={
                    search
                      ? 'Please try another search term'
                      : account.super_admin ? 'Click the "Upload File" button to begin' : ''
                  }
                  wrapperSx={{ mt: 25 }}
                />
              )}

              {files?.length !== 0 &&
                files.map((file) => (
                  <FileListItem
                    key={file.file_id}
                    handleFilePreview={() => setCurrentFilePreviewId(file.file_id)}
                    handleDownload={() => downloadFile({faId: id, fileId:file.file_id})}
                    markForDelete={() => setMarkedForDelete({ name: file.name, itemId: file.file_id })}
                    markForEdit={() => setMarkedForEdit({ name: file.name, fileId: file.file_id })}
                    faId={id}
                    {...file}
                  />
                ))}
            </Stack>

            <Stack direction="row" justifyContent="center">
              <Pagination handlePageChange={handlePageChange} itemCount={total} />
            </Stack>
          </>
          )}
        </>
        ) :
        (
          <></>
        )}
      </Stack>
    </>
  );

  function useRefetch() {
    useEffect(() => {
      refetchFiles();
    }, [page, search]);
  }

  function useTabReset() {
    useEffect(() => {
      if (currentTab !== filesTabName) {
        setPage(1);
        setSearch('');
      }
    }, [currentTab]);
  }
};
