import { axios } from 'lib';

export const downloadFile = async ({tenderID, fileId, tcID = null, cgId = null}) => {
  let url = `/api/tenders/${tenderID}/files/${fileId}`;
  if (tcID) {
    url = `/api/tenders/${tenderID}/suppliers/${tcID}/files/${fileId}`;
  }

  if (cgId) {
    url = `/api/tenders/${tenderID}/consortium_groups/${cgId}/files/${fileId}`;
  }

  const {
    data: { data },
  } = await axios.get(url);

  try {
    const response = await fetch(decodeURI(data.url));

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const filename = `${data.name}.${data.FileExt}`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    link.click();
  } catch (error) {
    console.log(error);
  }
};

export const donwloadFileFromURL = async (url, name) => {
  try {
    const response = await fetch(decodeURI(url));

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const filename = `${name}`;

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    link.click();
  } catch (error) {
    console.log(error);
  }
};
