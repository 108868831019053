import { useSnackbar } from 'notistack';

import { axios } from 'lib';
import { useMutation } from 'react-query';

const addCompaniesToFrameworkAgreement = ({ fa_id, companies_ids, company_type }) => {
  return axios.post(
    `/api/framework_agreements/${fa_id}/companies`,
    JSON.stringify({
      fa_id: +fa_id,
      companies_ids,
      company_type
    })
  );
};

export const useAddCompaniesToFrameworkAgreement = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: addCompaniesToFrameworkAgreement,
    onSuccess: () => {
      enqueueSnackbar('Companies successfully added to the framework agreement', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
