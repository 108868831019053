import * as yup from 'yup';

export const shadowCompanySchema = yup.object().shape({
  Country: yup.string().required('Required field'),
  Name: yup.string().required('Required field'),
  VAT: yup.string().required('Required field'),
  vatNumberConfirmation: yup
    .string()
    .required('Required field')
    .oneOf([yup.ref('VAT')], "VAT number didn't match"),
});
