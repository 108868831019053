import qs from 'qs';

import { axios } from 'lib';

export const getCompanies = async (type, searchTerm = '') => {
  const queryStringParams = qs.stringify({
    offset: 0,
    limit: 10,
    search: searchTerm,
    companytype: type,
    includeinactive: true,
  });

  const { data } = await axios.get(`/api/companies?${queryStringParams}`);
  console.log(data);

  return data;
};
