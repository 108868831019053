import React, { useState, useEffect} from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { EXTERNAL } from 'routes';
import { FormProvider, TextField, Select } from 'components/Form';
import { Modal } from 'components/Modal';

import { useCompanyUsersCount } from '../../companies/api/getCompanyUsersCount';
import { useSubscribe } from '../../subscriptions/api/subscribe';

import useYupValidationResolver from 'hook/useYupValidationResolver';
import { subscriptionSchema } from '../../subscriptions/validation';

import { useAuth } from 'lib/auth';

const getDefaultValues = (currentLevel, usersCount) => ({
  subscription_level: currentLevel,
  number_of_users: usersCount,
  promo_code: '',
});

const subscriptionLevelsSuppliers = [
  {
    label: 'Free',
    value: 'free',
  },
  {
    label: 'Silver 995 SEK / Month / user',
    value: 'silver',
  },
  {
    label: 'Gold 1.995 SEK / Month / user',
    value: 'gold',
  },
];

const subscriptionLevelsBuyers = [
  {
    label: 'Free',
    value: 'free',
  },
  {
    label: 'Silver 1.995 SEK / Month / user',
    value: 'silver',
  },
  {
    label: 'Gold 3.995 SEK / Month / user',
    value: 'gold',
  },
];

export const SuperAdminSubscribeForm = ({handleClose, handleSuccess, open, currentLevel, company = null }) => {
  const { user } = useAuth();
  const [ numberOfUsers, setNumberOfUsers ] = useState('');  
  const companyID = company?.company_id ? company.company_id : user.Company.company_id;
  const subscriptionLevels = company.Type === 'buyer' ? subscriptionLevelsBuyers : subscriptionLevelsSuppliers;
  
  const {
    //@ts-ignore
    data: { count: usersCount },
  } = useCompanyUsersCount(companyID);  

  const methods = useForm({
    mode: 'onSubmit',
    defaultValues: getDefaultValues(currentLevel, usersCount),
    resolver: useYupValidationResolver(subscriptionSchema),
  });

  const onSubmit = async (formData) => {
    if (formData.subscription_level !== 'free' && formData.number_of_users < usersCount) {
      methods.setError('users', { type: 'custom', message: `Can not be lower than number of active users (currently ${usersCount})` });
    } else {
      try {
        subscribe({...formData}, { onSuccess });
      } catch (error) {
        methods.setError('number_of_users', { type: 'custom', message: error.message });
      }
    }
  };  

  const handleNumberOfUsersChange = (value) => {
    setNumberOfUsers(value);
    methods.setValue("number_of_users", value);
  }

  const { mutate: subscribe } = useSubscribe(+companyID, +user.user_id);

  const onClose = () => {
    methods.reset({ ...getDefaultValues(currentLevel, usersCount) });
    setNumberOfUsers(usersCount)
    handleClose();
  };

  const onSuccess = () => {
    //handleSuccess();
    refreshPage();
    //onClose();
  };

  const refreshPage = () => {
    window.location.reload();
  }

  useEffect(() => {
    handleNumberOfUsersChange(usersCount)
  }, [companyID])  
  
  return (
    <Modal title="Current subscription level" handleClose={onClose} open={open} gap={0}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack>
        <Stack>
          <Typography variant="h6" sx={{fontWeight: 500, textTransform: 'capitalize', mb: 3 }}>
            {currentLevel}
          </Typography>
          <Select defaultValue={currentLevel} placeholder="Please select" required fullWidth name="subscription_level" label="New Subscription level" options={subscriptionLevels} sx={{ mb: 2 }} />
        </Stack>
        <Stack>
          <TextField
            name="number_of_users"
            label="Number of users"
            placeholder="Please enter number of users"
            required={true}
            type="text"
            value={numberOfUsers}
            onChange = {(e) => { handleNumberOfUsersChange(e.target.value); }}
            sx={{ mb: 2 }}
            fullWidth
            error={Boolean(methods?.formState?.errors?.users)}
            helperText={methods?.formState?.errors?.users?.message.toString()}
          />
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 0.5 }}
          >
            Change subscription
          </Button>
        </Stack>
      </Stack>
      </FormProvider>
    </Modal>
  );
};
