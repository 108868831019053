import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import { PaperCard } from 'components/Card';
import { paths } from 'routes';
import { useAuth } from 'lib/auth';
import { RedMessageBar } from 'components/RedMessageBar';

export const VerifyEmailForm = ({
  onSuccess,
  actionCode,
  apiKey,
  continueURL
}) => {
  const { activate, verifyActionCode } = useAuth();
  const [loading, setLoading] = useState(true);
  const [ error, setError ] = useState("");
  
  useEffect(() => {
    const verifyCode = async () => {
      try {
        await verifyActionCode(actionCode);
        await activateProfile();
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    const activateProfile = async () => {
      const url = new URL(continueURL);
      const firebaseUID = url.searchParams.get('firebase_uid');
      const accountID = url.searchParams.get('account_id');
      const userID = url.searchParams.get('user_id');
      const companyID = url.searchParams.get('company_id');
      await activate(firebaseUID, accountID, userID, companyID);
    }

    verifyCode();
  }, []);

  return (
    <>
    {!loading && (
    <PaperCard width={368} maxWidth={368} sx={{mb:5}}>
      <Stack>
        {error && (
          <>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Verify Email
            </Typography>
            <RedMessageBar message={error} />
            <Button size="large" variant="contained" color="primary" sx={{ mt: 2, mb: 0.5 }} href={paths.homepage}>
              Return to home
            </Button>
          </>
        )}
        {!error && (
          <>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Your email has been verified
            </Typography>
            <Stack>
              <Typography variant="body1">
                You can now sign in with your new account
              </Typography>

              <Button size="large" variant="contained" color="primary" sx={{ mt: 2, mb: 0.5 }} href={paths.homepage}>
                Proceed to login page
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </PaperCard>
    )}
    </>
  );
};
