import { parseISO, format } from 'date-fns/esm';
import localeSv from 'date-fns/locale/sv';

export const isoToDateString = (isoDateString, withTime = false) => {
  if (!isoDateString) return '';

  try {
    const date = parseISO(isoDateString);

    const resultingFormat = withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
    const formattedDate = format(date, resultingFormat, { locale: localeSv });
    return formattedDate;
  } catch (error) {
    console.debug('Input date string is not in ISO format');
    return '';
  }
};
