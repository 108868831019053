import { useSnackbar } from 'notistack';

import { axios } from 'lib';
import { useMutation } from 'react-query';

const addCompanies = ({ dps_id, companies_ids, company_type }) => {
  return axios.post(
    `/api/dps/${dps_id}/companies`,
    JSON.stringify({
      dps_id: +dps_id,
      companies_ids,
      company_type
    })
  );
};

export const useAddCompanies = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: addCompanies,
    onSuccess: () => {
      enqueueSnackbar('Companies successfully added', {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
