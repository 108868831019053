import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';

const getCompanyUsersCount = (companyID) => {
  return axios.get(
    `/api/companies/${companyID}/users/count`
  );
};

export const useCompanyUsersCount = (companyID, options = {}) =>
  useQuery(
    `company-${companyID}-users-count`,
    () => getCompanyUsersCount(companyID),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );