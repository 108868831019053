import { paths } from 'routes';
import { IconWrapper } from 'components/Icon';
import { useAuth } from 'lib/auth';

export function useNavItems() {
  const { account } = useAuth();
  const items = [
    {
      title: 'Agreements',
      path: paths.frameworkAgreement,
      Icon: ({ sx }) => <IconWrapper icon="documentText" sx={sx} />,
    },
    {
      title: 'Tenders',
      path: paths.tenders,
      Icon: ({ sx }) => <IconWrapper icon="clipboardDocumentList" sx={sx} />,
    },
    {
      title: 'DPS',
      path: paths.dps,
      Icon: ({ sx }) => <IconWrapper icon="documentCheck" sx={sx} />,
    },
    {
      title: 'Buyers',
      path: paths.buyers,
      Icon: ({ sx }) => <IconWrapper icon="buildingOffice" sx={sx} />,
    },
    {
      title: 'Suppliers',
      path: paths.suppliers,
      Icon: ({ sx }) => <IconWrapper icon="buildingOffice2" sx={sx} />,
    },
    {
      title: 'Users',
      path: paths.users,
      Icon: ({ sx }) => <IconWrapper icon="users" sx={sx} />,
    },
    ...account.super_admin ?
    [{
      title: 'Subscriptions',
      path: paths.subscriptions,
      Icon: ({ sx }) => <IconWrapper icon="subscription" sx={sx} />,
    }] : [],
    /* {
      title: 'Marketplace',
      path: paths.marketplace,
      Icon: ({ sx }) => <IconWrapper icon="marketplace" sx={sx} />,
      subItems: [
        //{ title: 'Framework agreements', path: paths.frameworkAgreement,Icon: ({ sx }) => <IconWrapper icon="documentText" sx={sx} />, },
        { title: 'DPS', path: paths.dps,Icon: ({ sx }) => <IconWrapper icon="documentDuplicate" sx={sx} />, },
        { title: 'Company profiles', path: paths.companyProfile,Icon: ({ sx }) => <IconWrapper icon="buildingOffice" sx={sx} />, },
      ],
    }, */
  ];

  return items;
}
