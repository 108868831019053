export const VERTICAL_NAV = {
  width: '256px',
  closedWidth: '75px',
};

export const LAYOUT = {
  // width: '1200px',
  width: {
    xs: 768,
    lg: 900,
    // lg: 943,
    xl: 1200,
  },
  smallHeaderHeight: '87px',
  largeHeaderHeight: { xs: '96px', lg: '112.5px', xl: '150px' },
};
