import React, { useCallback, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AccordionListItem } from 'components/Button';

import useYupValidationResolver from 'hook/useYupValidationResolver';

import { supplierConsortiumSchema } from '../validation';
import { useUpdateConsortiumGroup } from '../api/updateConsortiumGroup';
import { useConsortiumGroup } from '../api/getConsortiumGroupById';
import { Modal } from 'components/Modal';
import { FormProvider, TextField } from 'components/Form';

export const SupplierConsortiumForm = ({ refetch }) => {
  const { id: tenderID, cgId } = useParams();
  const [open, setOpen] = useState(false);

  const methods = useForm({
    defaultValues: {
      cg_name: '',
    },
    resolver: useYupValidationResolver(supplierConsortiumSchema),
  });

  const { data: consortiumGroup } = useConsortiumGroup(tenderID, cgId);
  const { mutateAsync: updateConsortiumGroup } = useUpdateConsortiumGroup();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onMutation = () => {
    refetch();
    setOpen(false);
  };

  const onSubmit = ({ cg_name }) => {
    try {
      updateConsortiumGroup({
        tenderID,
        cgId,
        cg_name: consortiumGroup.cg_name !== cg_name ? cg_name : undefined,
      })
        .then(onMutation)
        .catch(() => false);
    } catch (error) {}
  };

  useReset();

  return (
    <>
      <AccordionListItem onClick={() => setOpen(true)}>Edit</AccordionListItem>
      <Modal title="Edit Group Name" handleClose={handleClose} open={open}>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Stack gap={3}>
            <TextField name="cg_name" fullWidth required type="text" label="Group Name" placeholder="Enter Group Name" />

            <Stack direction="row" gap={3} justifyContent="center">
              <Button variant="outlined" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </Modal>
    </>
  );

  function useReset() {
    useEffect(() => {
      if (!open) {
        methods.reset({ cg_name: '' });
      }
    }, [open]);
  }
};
