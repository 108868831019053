import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const subscribe = async (companyID, userID, data) => {
  data.company_id = companyID
  data.user_id = userID
  const { data: subscription } = await axios.post(`/api/companies/${companyID}/subscriptions`, { ...data});

  return subscription;
};

export const useSubscribe = (companyID, userID) => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data) => subscribe(companyID, userID, data),
    onSuccess: () => {
      enqueueSnackbar('Subscription level changed successfuly', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
