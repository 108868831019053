import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getTendersCount = (expired, companyID) => {
  const queryString = qs.stringify({ expired });
  return axios.get(
    `/api/tenders/count?${queryString}`,
    {headers: {"company_id": companyID}}
  );
};

export const useTendersCount = (expired = false, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `tenders-expired-${expired}-count`,
    () => getTendersCount(expired, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}