import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ImageUpload } from 'components/ImageUpload';
import { LAYOUT } from 'components/Layout';

import { useImageUpload } from '../api/uploadImage';
import { IconWrapper } from 'components/Icon';

export const BannerImage = ({ sx = {}, banner, bannerChangable = false, refetch = null }) => {
  const { id: companyId } = useParams();
  const [open, setOpen] = useState(false);

  const { mutate: uploadImage } = useImageUpload(companyId, 'background', refetch);

  return (
    <>
      <ImageUpload
        image={banner}
        open={open}
        aspect={8 / 1}
        handleClose={() => setOpen(false)}
        uploadImage={uploadImage}
        modalSx={{
          width: LAYOUT.width,
        }}
      />

      <Box
        onClick={() => {
          if (bannerChangable) setOpen(true);
        }}
        width={LAYOUT.width}
        minHeight={LAYOUT.largeHeaderHeight}
        // position="absolute"
        top={0}
        left={0}
        sx={({ spacing }) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: bannerChangable ? 'pointer' : 'default',
          border: '1px solid',
          borderColor: 'grey.200',
          bgcolor: 'grey.300',
          borderRadius: `0px 0px ${spacing(2)} ${spacing(2)}`,
          overflow: 'hidden',

          img: {
            // borderRadius: `0px 0px ${spacing(2)} ${spacing(2)}`,
            height: 1,
            width: 1,
            // objectFit: { xs: 'fill', lg: 'cover' },
          },
        })}
      >
        {!banner && (
          <IconWrapper
            icon="photo"
            sx={{ svg: { width: 80, height: 80, mt: -4, stroke: 'white', color: 'white' } }}
          />
        )}

        {banner && <img src={banner} alt="banner" />}
      </Box>
    </>
  );
};
