import { axios } from "lib/axios"
import { auth } from "lib/firebase"
import { signInWithEmailAndPassword } from 'firebase/auth';

export const loginWithEmailAndPassword = async (data, hasUsers = true) => {
  try {
    await signInWithEmailAndPassword(auth, data.companyEmail, data.password);
    let account;
    if (hasUsers) {
      account = await getAccountData();
    } else {
      account = await getAccountDataOnly();
    }

    // const account = await getAccountData();

    return account.data;
  } catch (error) {
    if (error.code === 'auth/invalid-login-credentials') {
      throw new Error("Invalid credentials");
    } else if (error.code === 'auth/too-many-requests') {
      throw new Error("Too many requests. Please try again later or reset your password");
    } else {
      throw error;
    }
  }
}

export const getAccountData = async () => {
  const account = await axios.post("/auth/login")

  if (account.data.status !== "active") {
    throw new Error("Account inactive");
  }

  return account;
}

export const getAccountDataOnly = async () => {
  const account = await axios.post("/auth/accounts/verify")

  if (account.data.status !== "active") {
    throw new Error("Account inactive");
  }

  return account;
}