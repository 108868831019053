import React, { useState } from 'react';
import { Box, Button, Stack, Typography, Divider, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { PaperCard } from 'components/Card';
import { FormProvider, TextField, DateInput } from 'components/Form';

export const AdditionalDetails = ({ handleCloseModal, methods, onSubmit }) => {
  const [ promoted, setPromoted] = useState(false)
  const handlePromotedChange = () => {
    const newValue = !promoted;
    setPromoted(newValue);
    methods.setValue("promoted", newValue);
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: '#F3F4F6',
        justifyContent: 'center',
        display: 'flex',
        zIndex: 500,
        alignItems: 'center',
      }}
    >
      <Stack height={'100%'} width="100%">
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <PaperCard
            height={69}
            sx={{
              background: '#fff',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              px: 4,
              py: 2,
              border: 0,
              borderRadius: 0,
              boxShadow: 26,
              display: 'flex',
              boxSizing: 'border-box',
            }}
          >
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography>Editing ‘{methods.getValues('faName')}’</Typography>
              <Box
                sx={{
                  width: 165,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  size="medium"
                  onClick={handleCloseModal}
                  variant="contained"
                  sx={({ palette }) => ({
                    width: 82,
                    boxShadow: 25,
                    color: palette.primary.main,
                    backgroundColor: '#fff',
                  })}
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  // onClick={onSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ width: 67 }}
                >
                  Save
                </Button>
              </Box>
            </Stack>
          </PaperCard>

          <Stack
            direction="row"
            width="100%"
            height="100vh"
            mt={26.25}
            bgcolor={'#fff'}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Stack width={'50%'} direction="column" px={8} py={4}>
              <TextField
                name="faName"
                label="Framework Agreement Name"
                placeholder="Please enter Framework agreement name"
                required={true}
                type="text"
                sx={{ mb: 2 }}
                fullWidth
              />
              <TextField
                name="description"
                label="Description"
                placeholder="Please enter description"
                required={true}
                type="text"
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiInputBase-root, .MuiInputBase-input': {
                    p: 0,
                  },
                }}
                multiline
                minRows={2}
              />
              <FormGroup sx={{ 
                flex: '1 1 300px',
                alignItems:"baseline",
                '& .MuiFormControlLabel-root': {
                  m:0
                },
                '& .MuiFormControlLabel-label': {
                  fontSize:"0.75rem"
                },
               }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={promoted}
                      onChange={handlePromotedChange}
                      name="promoted"
                    />
                  }
                  label="Promoted"
                  labelPlacement="top"
                />
              </FormGroup>
            </Stack>

            <Stack
              width={'50%'}
              direction="row"
              justifyContent="space-between"
              px={8}
              py={4}
              gap={2}
            >
              <DateInput
                name="startDate"
                label="Start Date"
                required
                wrapperSx={{ mb: 2 }}
                fullWidth
              />
              <DateInput name="endDate" label="End Date" required wrapperSx={{ mb: 2 }} fullWidth />
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </Box>
  );
};
