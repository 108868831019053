import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

const getByIDRequest = async (id) => {
  return await axios.get(`/api/users/${id}`);
};

export const useUsersById = (id, config = {}) => {
  return useQuery(['user', id], () => getByIDRequest(id), {
    select: ({ data }) => data,
    ...config,
  });
};