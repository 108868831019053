import { axios } from 'lib/axios';
import { useQuery } from 'react-query';
import { useAuth } from 'lib/auth';

const getByIDRequest = async (id, companyID) => {
  if (id && id.length > 0) {
    return await axios.get(`/api/companies/${id}`, {headers: {"company_id": companyID}});
  }
};

export const useGetCompanyById = (id, config = {}) => {
  const { user } = useAuth();
  return useQuery(['company', id], () => getByIDRequest(id, user?.Company.company_id), {
    select: ({ data }) => data,
    ...config,
  });
};
