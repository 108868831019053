import qs from 'qs';
import { useQuery } from 'react-query';

import { axios } from 'lib';
import { useAuth } from 'lib/auth';

const getTendersConsortiumGroupFilesCount = (tenderID, tcID, companyID) => {
  return axios.get(
    `/api/tenders/${tenderID}/consortium_groups/${tcID}/files/count`, {headers: {"company_id": companyID}}
  );
};

export const useTendersConsortiumGroupFilesCount = (tenderID, tcID, options = {}) => {
  const { user } = useAuth();
  return useQuery(
    `tender-consortium-group-${tcID}-count`,
    () => getTendersConsortiumGroupFilesCount(tenderID, tcID, user.Company.company_id),
    {
      //@ts-ignore
      initialData: { data: { count: 0 } },
      select: ({ data }) => data,
      ...options,
    }
  );
}
