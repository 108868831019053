import React from 'react';
import { Box } from '@mui/material';
import { Head } from 'components/Head';

import { ContentHeader } from './ContentHeader';
import { LAYOUT } from '../config';

import { PremiumBanner } from '../../../features/subscriptions/components/PremiumBanner';
import { useAuth } from 'lib/auth';
import zIndex from '@mui/material/styles/zIndex';

export const ContentLayout = ({
  title = 'Delegon',
  headerSize = 'small',
  meta = [],
  children,
}) => {
  const {  account, user } = useAuth();
  return (
    <>
      <Head title={title} meta={meta} />
      <Box
        display="flex"
        flexDirection="column"
        flex="1 100%"
        width={1}
        bgcolor="background.default"
      >
        {!account.super_admin && (
          <Box>
            <PremiumBanner sx={{mt:0, position:"fixed", width:"100vw", zIndex:100, left: 0}} message={`Read more about our Premium offerings here`} currentLevel={user.Company.subscription_level} showLock={false} />
          </Box>
        )}
        <ContentHeader headerSize={headerSize} superAdmin={account.super_admin} />
        <Box
          pt={account.super_admin ? `calc(${LAYOUT.smallHeaderHeight} + 16px)` : `calc(${LAYOUT.smallHeaderHeight} + 68px)`}
          flex={1}
          width={LAYOUT.width}
          margin="auto"
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
