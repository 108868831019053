const GREY = {
  50: '#f9fafb',
  100: '#f3f4f6',
  200: '#e5e7eb',
  300: '#d1d5db',
  400: '#9ca3af',
  500: '#6b7280',
  600: '#4b5563',
  700: '#374151',
  800: '#1f2937',
  900: '#111827',
};

export const palette = {
  primary: {
    //main: '#003840',
    main: '#006774',
  },
  border: {
    main: GREY[200],
  },
  divider: GREY[200],
  grey: GREY,

  background: {
    paper: '#fff',
    default: GREY[100],
    disabled: GREY[200],
  },

  success: {
    main: '#2e7d32',
    light: '#22C55E',
    dark: '#1b5e20',
    contrastText: '#fff',
  },

  text: {
    primary: GREY[900],
    secondary: GREY[500],
    disabled: GREY[500],
  },

  warning: {
    main: '#FDB526',
  },

  info: {
    main: '#fff',
    contrastText: GREY[500]
  },

  secondary: {
    main: '#fff',
    dark: GREY[200],
    contrastText: GREY[500],
  },

  purple:'#EBE6FC',
};
