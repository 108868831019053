import { Box } from '@mui/material';

export const PaperCard = ({ children, ...rest }) => {
  return (
    <Box
      borderRadius={2}
      p={4}
      boxShadow={27}
      border="1px solid"
      borderColor="border.main"
      {...rest}
    >
      {children}
    </Box>
  );
};
