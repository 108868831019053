import { axios } from "lib/axios"
import { auth } from "lib/firebase"
import { sendPasswordResetEmail } from 'firebase/auth';
import { paths } from 'routes';
import { API_URL } from 'configuration';

export const forgotPassword = async (email) => {
  try {
    const actionCodeSettings = {
      url: `${API_URL}${paths.auth.verifyEmail}?email=${email}`,
    };
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

