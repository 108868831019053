import React from 'react';

import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { useAuth } from 'lib/auth';

export const RelatedFrameworkAgreementsListItem = ({ fa_name: name, Status: status, fa_id: id, MainBuyer }) => {  
    const { account } = useAuth();
    return (
      <ListCard>
        <ImageAvatar img={MainBuyer?.square_logo_url || ''} name={name} />
        <Stack flex={7} justifyContent="center">
          <Link
            component={RouterLink}
            to={`/app/framework-agreements/${id.toString()}`}
            color="inherit"
          >
            <Typography variant="body1" fontWeight={700}>
              {name}
            </Typography>
          </Link>
        </Stack>

        {account.super_admin && (
          <StatusComponent status={status} />
        )}
      </ListCard>
    );
  };
