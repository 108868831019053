import React from 'react';

import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ImageAvatar } from 'components/Avatar';
import { ListCard } from 'components/Card';
import { Status as StatusComponent } from 'components/Status';

import { useAuth } from 'lib/auth';

export const RelatedCompaniesListItem = ({
  Name: name,
  Status: status,
  VAT: vatNumber,
  company_id: id,
  Type: companyType,
  square_logo_url,
}) => {
  const { account } = useAuth();
  return (
    <ListCard>
      <ImageAvatar img={square_logo_url || ''} name={name} />
      <Stack flex={7} justifyContent="center">
        <Link
          component={RouterLink}
          to={`/app/${companyType}s/${id.toString()}`}
          color="inherit"
        >
          <Typography variant="body1" fontWeight={700}>
            {name}
          </Typography>
        </Link>
        <Stack direction="row" gap={1}>
          <Typography variant="caption" fontWeight={600}>
            {vatNumber}
          </Typography>
        </Stack>
      </Stack>
      {account.super_admin && (
        <StatusComponent status={status} />
      )}
    </ListCard>
  );
};
