import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Box, Typography, Tooltip, Stack } from '@mui/material';
import { useAuth } from 'lib/auth';
import { ReactComponent as LockIcon } from 'assets/LockClosed.svg';

export const OrderByDropdown = ({ name, onChange, selected, adminOnly = false, options = [] }) => {
  const {
    account: { super_admin },
    user
  } = useAuth();

  if (adminOnly && !super_admin) return null;

  return (
    <FormControl sx={{ width: 210 }}>
      <Box display="flex" alignItems="center">
        <Typography variant='caption' sx={{width: 60}}>Sort by</Typography>
        <Select
          name={name}
          labelId="order-by-label"
          value={selected}
          label="Sort by"
          onChange={onChange}
          sx={{width: 150, height:42}}
        >
          {options.map(({ value, text, subscriptionType = [], tooltip }) => {
            const disabled = subscriptionType.length && !subscriptionType.includes(user.Company.subscription_level) && !super_admin;
            if (disabled) {
              return (
                <Tooltip
                  arrow
                  title={tooltip}
                  placement="top"
                  slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14] } }] } }}
                >
                  <Stack>
                    <MenuItem key={value} value={value} disabled={disabled}>
                      {text}
                      <Stack
                        sx={{
                          pl:1,
                        }}
                      >
                        <LockIcon />
                      </Stack>
                    </MenuItem>
                  </Stack>
                </Tooltip>
              );
            }

            return (
              <MenuItem key={value} value={value} disabled={disabled}>
                {text + (disabled ? ` (${tooltip})` : '')}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    </FormControl>
  );
};
