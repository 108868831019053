import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';

import { axios } from 'lib';

const removeCompany = ({ dpsID, dcID }) => {
  return axios.delete(`/api/dps/${dpsID}/companies/${dcID}`);
};

export const useRemoveCompany = (type, onCompanyRemoval) => {
  const { enqueueSnackbar } = useSnackbar();
  const snackMessage =
    type === 'buyer'
      ? 'Buyer successfully removed'
      : 'Supplier successfully removed';

  return useMutation({
    mutationFn: removeCompany,
    onSuccess: () => {
      enqueueSnackbar(snackMessage, {
        variant: 'success',
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      });

      onCompanyRemoval();
    },
    onError: (error) => {
      enqueueSnackbar(error?.message || 'Some error occured', { variant: 'error' });
    },
  });
};
